import {makeAutoObservable, configure} from "mobx";
import {toJS} from "mobx";
import {API_URL} from "../http";
// import $api from '../http';
import axios from 'axios';
import AuthService from "../services/AuthService";




configure({
    enforceActions: "never",

})


export default class Store {
    user = {};
    isAuth = false;
    isLoading = false
    cardToEditId = ""
    userToEditId = ""
    merchantToEditId = ""
    supportToEditId = ""
    teamleadToEditId = ""
    pageTitle = ""


    constructor() {
        //функция вызывается для того чтобы mobx мог работать с данным классом
        makeAutoObservable(this)
        //эта штука привязывает контекст (this) к фцункции logout, хуй знает почему он отвязался
        // this.logout = this.logout.bind(this);
        //либо можно заменить функцию на стрелочную, они не теряют контест (это я и сделал в данном случае, но
        // оставил старую функцию в комментариях)
    }


    setPageTitle(title) {
        this.pageTitle = title
    }


    setTeamleadToEditId(teamleadIdId) {
        this.teamleadToEditId = teamleadIdId
    }

    setSupportToEditId(supportId) {
        this.supportToEditId = supportId
    }

    setMerchantToEditId(merchantId) {
        this.merchantToEditId = merchantId
    }


    setUserToEditId(userId) {
        this.userToEditId = userId
    }


    setCardToEditId(cardId) {
        this.cardToEditId = cardId
    }


    setAuth(bool) {
        this.isAuth = bool
    }
    
    
    setUser(user) {
        this.user = user
    }

    setIsLoading(bool) {
        this.isLoading = bool
    }
    
    
    async login(login, password, browserId) {
        try {
            const response = await AuthService.login(login, password, browserId)

            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
            return "OK"
        } catch (e) {
            console.log(e.response?.data?.message)
            return "FAIL"
        }
    }


    async createUser(login, password) {
        try {
            const response = await AuthService.createUser(login, password)
            // localStorage.setItem('token', response.data.accessToken)
            // this.setAuth(true)
            // this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    logout = async (browserId) => {
        try {
            const response = await AuthService.logout(browserId);

            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({});

        } catch (e) {
            console.log(e);
            console.log(e.response?.data?.message);
        }
    }


    // async logout() {
    //     try {
    //         const response = await AuthService.logout()
    //
    //         console.log(response.data)
    //
    //         localStorage.removeItem('token')
    //         this.setAuth(false)
    //         this.setUser({})
    //         this.checkAuth()
    //     } catch (e) {
    //         console.log(e)
    //         console.log(e.response?.data?.message)
    //     }
    // }


    async checkAuth(browserId) {
        this.setIsLoading(true)
        try {
            const response = await axios.get(`${API_URL}/refresh?browserId=${browserId}`, {withCredentials: true})

            console.log(response.data)
            localStorage.setItem('token', response.data.accessToken);
            // localStorage.setItem('currentUser', response.data.email)
            this.setAuth(true);
            this.setUser(response.data.user)


        } catch (e) {
            console.log(e.response?.data?.message)
        } finally {
            this.setIsLoading(false)
        }
    }

}
