import React, {useContext, useEffect, useRef, useState} from "react";
import {Routes, Route, Link, useLocation} from "react-router-dom";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import './css/styles.css'
import Header from "./components/Header";
import Statistics from "./components/Statistics";
import SideMenu from "./components/SideMenu";
import LoginForm from "./components/LoginForm";
import CardCreator from "./components/CardCreator";
import UserCreator from "./components/UserCreator";
import EmptyMessage from "./components/EmptyMessage";
import TestSend from "./components/TestSend";
import PersonalData from "./components/PersonalData";
import TopUps from "./components/TopUps";
import CardViewer from "./components/CardViewer";
import TraderViewer from "./components/TraderViewer";
import DeviceViewer from "./components/DeviceViewer";
import TokenCreator from "./components/TokenCreator";
import $api, {API_IP} from "./http";
import MerchantCreationForm from "./components/helpers/MerchantCreationForm";
import MerchantViewer from "./components/MerchantViewer";
import OrderViewer from "./components/OrderViewer";
import Settings from "./components/Settings";
import SmsViewer from "./components/SmsViewer";
import ProfitViewer from "./components/ProfitViewer";
import SupportViewer from "./components/SupportViewer";
import WsLogger from "./components/helpers/WsLogger";
import AppealViewer from "./components/AppealViewer";
import TeamleadViewer from "./components/TeamleadViewer";
import {toJS} from "mobx";


function App() {

    const {store} = useContext(Context)

    const notifySocket = useRef()
    const [connected, setConnected] = useState(false)

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    //websockets service variables
    const [updatedDevice, setUpdatedDevice] = useState({})
    const [freshStatistics, setFreshStatistics] = useState({})
    const [completedTraderOrder, setCompletedTraderOrder] = useState({})
    const [openedTraderOrder, setOpenedTraderOrder] = useState({})
    const [appealSupportUpdate, setAppealSupportUpdate] = useState({})
    const [appealTraderUpdate, setAppealTraderUpdate] = useState({})


    const [logString, setLogString] = useState("")

    useEffect(() => {
        if(localStorage.getItem('token')) {
            // console.log("GO check auth")
            store.checkAuth(localStorage.getItem('browserId'))
        }
    }, []);


    async function connectToWebSocket() {

        notifySocket.current = new WebSocket(`wss://${API_IP}:3030`,)

        notifySocket.current.onopen = async () => {


            let prefix = store.user.roles.includes("support")
                ?
                "support"
                :
                store.user.roles.includes("trader")
                    ?
                    ""
                    :
                    ""


            const message = {
                event: 'connection-front',
                user: prefix + store.user.id,

            }
            //await console.log({...store.user}.id)
            await notifySocket.current.send(JSON.stringify(message))
            setConnected(true)
            console.log("NOTIFY  подключение установлено")
        }

        notifySocket.current.onmessage = (event) => {
            const message = JSON.parse(event.data)
            console.log(message)


            if(message.event === "device_status") {
                setUpdatedDevice(message.data)
            }


            if(message.event === "logout") {
                store.logout(localStorage.getItem('browserId'))
            }

            if(message.event === "statistics_refresh") {
                setFreshStatistics(message.data)
            }

            if(message.event === "trader_order_completed") {


                setCompletedTraderOrder(message.data)
            }

            if(message.event === "trader_order_opened") {
                setOpenedTraderOrder(message.data)
            }


            if(message.event.startsWith("appeal_support")) {
                console.log("STARTS WITH SUPPORT")
                setAppealSupportUpdate(message)
            }

            if(message.event.startsWith("appeal_trader")) {
                console.log("STARTS WITH TRADER")
                setAppealTraderUpdate(message)
            }


        }




        notifySocket.current.onclose = () => {
            console.log("Подключение NOTIFIER закрылось")
            setConnected(false)
            setTimeout(connectToWebSocket, 3000)


        }

        notifySocket.current.onerror = () => {
            console.log("Ошибка сокета")
        }
    }

    useEffect(() => {
        if(store.user?.id) {
            connectToWebSocket()
        }
    }, [store.user?.id]);





    const [traders, setTraders] = useState([])
    const [merchants, setMerchants] = useState([])


    const getTraders = async () => {

        const response = await $api.get(`/get_users?requesterId=${store.user.id}`)
        console.log(response.data)
        setTraders(response.data)

    }

    const getMerchants = async () => {
        const response = await $api.get(`/get_merchants?requesterId=${store.user.id}`)
        console.log(response.data)
        setMerchants(response.data)

    }


    useEffect(() => {

        console.log(toJS(store))
        if(store.user?.roles?.includes("support")) {
            console.log("GETTING MERCHANTS AND TRADERS")
            getMerchants()
            getTraders()
        }

    }, [store.user])






    if(store.isLoading) {
        return  (
            <div></div>
            // <Loading/>
        )
    }


    if(!store.isAuth) {
        return (
            <div className="App">
                <Routes>

                    <Route path={'/ws_logs'} element={<WsLogger logString={logString}/>}/>
                    <Route path={"*"} element={<LoginForm/>}/>

                </Routes>

            </div>
        )
    }




    return (
      <div className="App">


          <Header
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              store={store}
          />
          <SideMenu
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              store={store}
          />
          <div
              className={`main ${isMenuOpen ? 'menu-open' : 'menu-closed'}`}
          >
              <Routes>

                  <Route path={'/ws_logs'} element={<WsLogger logString={logString}/>}/>

                  {store.user.roles.includes("trader")
                      ?
                      <>
                          <Route path={'/'} element={<Statistics store={store} freshStatistics={freshStatistics}/>}/>
                          <Route path={'/create_card'} element={<CardCreator store={store}/>}/>
                          <Route path={'/personal_data'} element={<PersonalData store={store}/>}/>
                          <Route path={'/top_ups'} element={<TopUps store={store}/>}/>
                          <Route path={'/card_viewer'} element={<CardViewer store={store}/>}/>
                          <Route path={'/device_viewer'} element={<DeviceViewer store={store} updatedDevice={updatedDevice}/>}/>
                          <Route path={'/browse_orders'} element={
                              <OrderViewer
                                  store={store}
                                  completedTraderOrder={completedTraderOrder}
                                  setCompletedTraderOrder={setCompletedTraderOrder}
                                  openedTraderOrder={openedTraderOrder}
                                  setOpenedTraderOrder={setOpenedTraderOrder}
                              />
                          }/>
                          <Route path={"/appeals"} element={<AppealViewer store={store} appealTraderUpdate={appealTraderUpdate}/>}/>
                          <Route path={'/sms_viewer'} element={<SmsViewer store={store}/>}/>
                          <Route path={'/profits'} element={<ProfitViewer store={store}/>}/>
                      </>
                      :
                      null
                  }
                  {store.user.roles.includes("merchant")
                      ?
                      <>
                          <Route path={'/browse_orders'} element={<OrderViewer store={store}/>}/>
                          <Route path={'/api_keys'} element={<TokenCreator store={store}/>}/>
                          <Route path={'/profits'} element={<ProfitViewer store={store}/>}/>
                      </>
                      :
                      null
                  }
                  {store.user.roles.includes("teamlead")
                      ?
                      <>
                          <Route path={'/profits'} element={<ProfitViewer store={store}/>}/>
                      </>
                      :
                      null
                  }
                  {store.user.roles.includes("support")
                      ?
                      <>
                          <Route path={"/appeals"} element={
                              <AppealViewer
                                  store={store}
                                  appealSupportUpdate={appealSupportUpdate}
                                  traders={traders}
                                  merchants={merchants}
                              />
                          }/>
                          <Route
                              path={'/card_viewer'}
                              element={<CardViewer store={store} traders={traders} updatedDevice={updatedDevice}/>}
                          />

                      </>
                      :
                      null
                  }


                  {store.user.admin
                      ?
                      <>
                          <Route path={'/create_user'} element={<UserCreator store={store}/>}/>
                          <Route path={'/user_viewer'} element={<TraderViewer store={store}/>}/>
                          <Route path={'/merchant_viewer'} element={<MerchantViewer store={store}/>}/>
                          <Route path={'/support_viewer'} element={<SupportViewer store={store}/>}/>
                          <Route path={'/teamlead_viewer'} element={<TeamleadViewer store={store}/>}/>
                          {/*<Route path={'/test_send'} element={<TestSend store={store}/>}/>*/}
                          <Route path={'/settings'} element={<Settings store={store}/>}/>
                          <Route path={'/profits'} element={<ProfitViewer store={store}/>}/>
                      </>
                          :
                      null
                  }



                  <Route path={"*"} element={<EmptyMessage message={"Такой страницы не существует"}/>}/>



              </Routes>

          </div>
      </div>
    );
}

export default observer(App);
