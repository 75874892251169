import React, {useRef, useEffect, useState} from 'react';
import $api from "../http";
import "../css/card-creator.css"
import "../css/order-viewer.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import {removeDuplicatesById, throttle} from "./helpers/helper-functions";
import MerchantOrderTable from "./helpers/MerchantOrderTable";
import TraderOrderTable from "./helpers/TraderOrderTable";

const OrderViewer = ({store, completedTraderOrder = {}, setCompletedTraderOrder, openedTraderOrder = {}, setOpenedTraderOrder}) => {

    // const navigate = useNavigate()


    console.log(completedTraderOrder)

    const [orders, setOrders] = useState([])
    const [skipCounter, setSkipCounter] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)

    const [processing, setProcessing] = useState(true)

    const startSearchTimer = useRef()


    const [filter, setFilter] = useState({})

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [loading, setLoading] = useState(false)



    // const fetchOrders = useCallback((filter, currentAmount) => getOrders(filter, currentAmount), [currentAmount])


    const getOrders = async (filter, skip) => {


        setFetching(false)
        setLoading(true)


        const response = await $api.get(`/get_orders?filter=${encodeURIComponent( JSON.stringify(filter) )}&skip=${skip}`)
        console.log(response.data)
        setProcessing(false)
        if(response.data.stopFetching) {
            setStopFetching(true)
            setSkipCounter(0)
        }
        setSkipCounter(prev => prev + response.data.orders.length)
        setOrders(prev => [...prev, ...response.data.orders])
        setLoading(false)

    }





    useEffect(() => {
        store.setPageTitle("Заявки")

        console.log("TRIGGERING", fetching, !stopFetching, skipCounter)

        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getOrders(filter, skipCounter)
            }, 1500)
        }


    }, [fetching, skipCounter, stopFetching]);


    useEffect(() => {
        // Проверяем, что completedTraderOrder не пустой объект
        if (
            !processing &&
            Object.keys(completedTraderOrder).length > 0 && // Проверка, чтобы избежать пустого объекта
            (!filter.stopDate || completedTraderOrder.date <= filter.stopDate) &&
            (!filter.startDate || completedTraderOrder.date >= filter.startDate) &&
            !filter?.status
        ) {
            setOrders(prev => {
                const newOrderState = prev.map(order => {
                    // Проверка на совпадение _id и замена объекта
                    return order.id !== completedTraderOrder.id ? order : completedTraderOrder;
                });

                const finalOrderState = removeDuplicatesById(newOrderState)

                return finalOrderState;
            });

            // Обнуление состояния завершенного заказа, но только если был установлен
            if (Object.keys(completedTraderOrder).length > 0) {
                console.log("SETTING TO 0 1")
                setCompletedTraderOrder({});
            }
        } else if(
            !processing &&
            completedTraderOrder?.status !== filter?.status && filter?.status &&
            Object.keys(completedTraderOrder).length > 0
        ) {
            setOrders(prev => {
                const newOrderState = prev.map(order => {
                    // Проверка на совпадение _id и замена объекта
                    return completedTraderOrder.id === order.id && completedTraderOrder?.status !== filter?.status ? null : order;
                });

                const preFinalOrderState = newOrderState.filter(order => order !== null)

                const finalOrderState = removeDuplicatesById(preFinalOrderState)

                return finalOrderState;
            });

            // Обнуление состояния завершенного заказа, но только если был установлен
            if (Object.keys(completedTraderOrder).length > 0) {
                console.log("SETTING TO 0 2")
                setCompletedTraderOrder({});
            }
        } else if(
            !processing &&
            completedTraderOrder?.status === filter?.status &&
            Object.keys(completedTraderOrder).length > 0
        ) {
            setOrders(prev => {
                const preFinalOrderState = [completedTraderOrder, ...prev]
                const finalOrderState = removeDuplicatesById(preFinalOrderState)
                return finalOrderState

            })
            if (Object.keys(completedTraderOrder).length > 0) {
                console.log("SETTING TO 0 3")
                setCompletedTraderOrder({});
            }
        } else {

            console.log(completedTraderOrder)

            console.log(!processing)
            console.log(completedTraderOrder?.status === filter?.status)
            console.log(Object.keys(completedTraderOrder).length > 0)
            console.log(completedTraderOrder)
            console.log(filter)
        }


    }, [completedTraderOrder, filter, processing]);




    useEffect(() => {
        if(
            !processing &&
            Object.keys(openedTraderOrder).length &&
            (openedTraderOrder?.status === filter?.status || !filter?.status)
        ) {

            setOrders(prev => {
                const preFinalOrderState = [openedTraderOrder, ...prev]
                const finalOrderState = removeDuplicatesById(preFinalOrderState)
                return finalOrderState

            })
            setOpenedTraderOrder({})
        }

    }, [openedTraderOrder, filter, processing])



    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ
        document.addEventListener('scroll', throttle(scrollHandler, 300))

        //при размонтировании элемента эта функция так же отключает слушатель события
        return function() {
            console.log("DO REMOVE")
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [])


    function scrollHandler(e) {

        console.log("SCROLLING")

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)

        }


        //console.log('Общая высота', e.target.documentElement.scrollHeight)
        //console.log('Текущее положение', e.target.documentElement.scrollTop)
        //console.log('Высота видимой области', window.innerHeight)

    }



    return (
        <div className={"card-viewer-wrapper"}>

            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));

                        setOrders([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <label>По</label>
                <input
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));

                        setOrders([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <select
                    defaultValue={""}
                    value={filter?.status}
                    onChange={(e) => {
                        setProcessing(true)
                        setFilter(prev => ({
                            ...prev,
                            status: e.target.value
                        }))

                        setOrders([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >
                    <option value={""}>Все</option>
                    <option value={"completed"}>Успешные</option>
                    <option value={"opened"}>Открытые</option>
                    <option value={"failed"}>Неудачные</option>
                </select>
                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        setProcessing(true)
                        setFilter({status: "", startDate: "", stopDate: ""})
                        setOrders([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >Сброс
                </button>


            </div>
            {store.user.roles[0] === "merchant"
                ?
                <MerchantOrderTable orders={orders} processing={processing}/>
                :
                <TraderOrderTable orders={orders} processing={processing}/>
            }


            {/*<table className="transaction-table">*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th>Внутренний номер</th>*/}
            {/*        <th>Дата</th>*/}
            {/*        <th>Сумма (руб)</th>*/}
            {/*        <th>Сумма (usdt)</th>*/}
            {/*        <th>Статус</th>*/}
            {/*        <th className={"fixed-table-width"}>Профит (usdt)</th>*/}

            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {orders?.map((order, index) => {*/}
            {/*        // const formattedDate = new Date(card.block_timestamp)*/}
            {/*        //     .toLocaleString('ru-RU', options);*/}

            {/*        return (*/}
            {/*            <tr*/}
            {/*                key={order._id}*/}
            {/*                className={*/}
            {/*                    order?.status === "failed"*/}
            {/*                        ?*/}
            {/*                        "red-row"*/}
            {/*                        :*/}
            {/*                        index % 2 === 0*/}
            {/*                            ?*/}
            {/*                            'even-row'*/}
            {/*                            :*/}
            {/*                            'odd-row'*/}
            {/*                }*/}
            {/*            >*/}
            {/*                <td>{order.number}</td>*/}
            {/*                <td>{new Date(order.openDate).toLocaleString([],{year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute:'2-digit'})}</td>*/}
            {/*                <td>{order.amount}</td>*/}
            {/*                <td>{order.usdtAmount?.toFixed(2)}</td>*/}
            {/*                <td>{order?.status}</td>*/}
            {/*                <td>{order.merchantProfit?.toFixed(2)}</td>*/}

            {/*            </tr>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*    </tbody>*/}
            {/*</table>*/}


            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />
        </div>
    );
};

export default OrderViewer;