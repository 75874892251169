import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/user-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import {toJS} from "mobx";

const TraderViewer = ({store}) => {


    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState({status: false, index: null})

    const [users, setUsers] = useState([])

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const getUsers = async () => {
        setLoading(true)

        console.log(toJS(store.user.id))

        const response = await $api.get(`/get_users?requesterId=${store.user.id}`)
        console.log(response.data)


        // Считаем сумму всех приоритетов
        const totalPriority = response.data.reduce((sum, user) => sum + user.priority, 0);

// Добавляем каждому пользователю ключ percent
        const middleUserState = response.data.map(user => {
            return {
                ...user,
                percent: ((user.priority / totalPriority) * 100).toFixed(2) // Округляем до 2 знаков после запятой
            };
        });




        setUsers(middleUserState)
        setLoading(false)
    }

    const changeTraderStatus = async (userId, index) => {

        setProcessing({status: true, index})

        try {
            console.log(userId, index)
            const response = await $api.get(`/change_trader_status?userId=${userId}`)
            console.log(response.data)

            // Получаем обновленный объект карты из ответа
            const updatedUser = response.data;

            // Обновляем состояние карт
            setUsers(prevUsers =>
                prevUsers.map(user =>
                    user.id === updatedUser.id
                        ? { ...user, active: updatedUser.active } // Копируем все поля и меняем только поле active
                        : user
                )
            );

        } catch (e) {
            console.log(e)
        }




        setProcessing({status: false, index})

    }


    useEffect(() => {
        store.setPageTitle("Трейдеры")
        getUsers()
    }, []);

    return (
        <div className={"user-viewer-wrapper"}>
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>NickName</th>
                    <th>Баланс [USDT]</th>
                    {/*<th>Баланс [TRX]</th>*/}
                    <th>Сделки (сегодня / всего)</th>
                    {/*<th>ID</th>*/}
                    <th>Cтавка (%)</th>
                    <th>Приоритет</th>
                    <th>Получит заявок</th>
                    <th className={"fixed-table-width"}>Активность</th>
                    <th className={"fixed-table-width"}>Изменение</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr key={user.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{user.login}</td>
                            {/*<td>{user.balanceOnWallet?.usdt.toFixed(2) + " / " + user.balanceOnPlatform.toFixed(2)}</td>*/}
                            <td>{user.balanceOnPlatform.toFixed(2)}</td>
                            {/*<td>{user.balanceOnWallet?.trx.toFixed(2)}</td>*/}
                            <td>{user.todaysOrdersCount + " / " + user.allOrdersCount}</td>
                            {/*<td>{user.id}</td>*/}
                            <td>{user.stake}</td>
                            <td>{user.priority}</td>
                            <td>{user.percent} %</td>
                            <td>
                                {user.active
                                    ?
                                    <button
                                        className={"gradient-red-button"}
                                        onClick={() => {

                                            changeTraderStatus(user.id, index)

                                        }}
                                    >Отключить
                                        {
                                            processing.status && processing.index === index
                                                ?
                                                <img src={require('../img/loading.gif')} height={"15px"}
                                                     className={"margin-left-20"}/>
                                                :
                                                null
                                        }
                                    </button>
                                    :
                                    <button
                                        className={"gradient-green-button card-status-switch-btn"}
                                        onClick={() => {

                                            changeTraderStatus(user.id, index)
                                        }}
                                    >Включить
                                        {processing.status && processing.index === index ?
                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                 className={"margin-left-20"}/> : null}
                                    </button>
                                }
                            </td>

                            <td>
                                <button
                                    className={"gradient-orange-button"}
                                    onClick={() => {
                                        store.setUserToEditId(user.id)
                                        navigate('/create_user')

                                    }}
                                >Редактировать</button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {loading
                ?
                <img src={require('../img/loading.gif')} height={"35px"} className={"margin-left-20"}/>
                :
                null
            }
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />
        </div>
    );
};

export default TraderViewer;