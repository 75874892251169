import React, {useState, useEffect} from 'react';
import "../../css/user-creator.css"
import $api from "../../http";
import {useNavigate} from "react-router-dom";
import {generatePassword} from "./helper-functions";


const MerchantCreationForm = ({store, createError, setCreateError}) => {

    const navigate = useNavigate()

    const [merchantData, setMerchantData] = useState({})
    const [showOk, setShowOk] = useState(false)


    const getMerchantToEdit = async (merchantId) => {
        const response = await $api.get(`/get_merchant_by_id?merchantId=${merchantId}`)
        console.log(response.data)
        setMerchantData(prev => {
            setCreateError({show: false})
            return {
                ...prev,
                id: response.data._id,
                login: response.data.login,
                stake: parseFloat(response.data.stake)
            }
        })
    }


    useEffect(() => {
        console.log(merchantData)
    }, [merchantData])


    useEffect(() => {

        if(store.merchantToEditId) {
            getMerchantToEdit(store.merchantToEditId)
        }

        return () => {
            store.setMerchantToEditId("")
        }

    }, []);



    const createMerchant = async (merchantData) => {
        // Список обязательных полей
        const requiredFields = [
            'login',
            // 'password',
            'stake'


        ];

        if(!store.merchantToEditId) {
            requiredFields.push("password")
        }


        // Проверка на заполнение всех полей
        const allFieldsFilled = requiredFields.every(field => {
            console.log(field, merchantData[field] !== undefined, merchantData[field] !== null, merchantData[field] !== '')
            return merchantData[field] !== undefined && merchantData[field] !== null && merchantData[field] !== '';
        });

        if (allFieldsFilled) {
            // Если все поля заполнены

            console.log(merchantData)
            console.log("Всё норм создаем мерчанта")
            try {
                const response = await $api.post('/create_merchant', {merchantData: JSON.stringify(merchantData)})
                if(response.data === "OK") {
                    setShowOk(true)
                    setMerchantData({
                        'login': "",
                        'stake': "",
                        'password': ""

                    })
                    setTimeout(() => {
                        setShowOk(false)
                        store.setMerchantToEditId("")
                        navigate('/merchant_viewer')
                    }, store.merchantToEditId ? 2000 : 5000)
                } else {
                    setCreateError({show: true, message: response.data.message})
                }

            } catch (e) {
                console.log(e)
                setCreateError({show: true, message: "Ошибка создания мерчанта"})
            }



        } else {
            // Если какие-то поля не заполнены, выполняем другое действие
            setCreateError({show: true, message: "Заполнены не все поля"})
        }
    };




    function validateInputLogin(input) {

        const validPattern = /^[A-Za-z0-9]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setMerchantData(prev => {
                setCreateError({show: false})
                return {
                    ...prev,
                    login: input
                }
            })
        }



    }


    function validateInputStake(input) {
        // Регулярное выражение для чисел от 0.1 до 10, включая дробные
        const validPattern = /^(10|[0-9](\.\d+)?|0\.[1-9]+)?$/;
        const valid = validPattern.test(input);

        if (valid) {
            setMerchantData(prev => ({
                ...prev,
                stake: input !== "" ? parseFloat(input.toString().replace(",", ".")) : ""
            }));
            setCreateError({ show: false });
        } else {
            setCreateError({ show: true, message: "Введите число от 0.1 до 10" });
        }
    }


    return (
        <div className={"merchant-creator-wrapper"}>
            <h1 className={"component-title"}>{store.merchantToEditId ? "Редактируем" : "Создаём"} мерчанта</h1>

            <input
                className={"user-creator-input"}
                type={"text"}
                pattern="[A-Za-z0-9]+"
                placeholder={"Имя (латинскими буквами без пробелов)"}
                value={merchantData.login}
                onChange={(e) => {
                    validateInputLogin(e.target.value)

                }}
            />

            <div className={"user-creator-password-input-n-generator-container"}>
                <input
                    readOnly={true}
                    className={"user-creator-input password-input"}
                    type={"text"}
                    placeholder={"password"}
                    value={merchantData.password}

                />
                <button
                    className={"gradient-dark-button pass-gen-btn"}
                    onClick={() => {
                        const freshPass = generatePassword()
                        setMerchantData(prev => {
                            setCreateError({show: false})
                            return {
                                ...prev,
                                password: freshPass
                            }
                        })
                    }}
                >{store.merchantToEditId ? "New Password" : "Generate"}
                </button>
            </div>

            <label
                className={"password-warning red-for-blue"}
            >{merchantData.password
                ?
                `Скопируйте пароль, после ${store.merchantToEditId ? 'сохранения' : 'создания'} мерчанта его больше нельзя будет увидеть`
                :
                null
            }
            </label>

            {/*<label*/}
            {/*    className={"password-warning red-for-blue"}*/}
            {/*>Скопируйте пароль, его можно увидеть только один раз. После {store.userToEditId ? "сохранения" : "создания"} пользователя он исчезнет с экрана</label>*/}


            <input
                className={"user-creator-input"}
                type={"number"}
                placeholder={"Ставка (%)"}
                value={merchantData.stake}
                onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                        e.preventDefault(); // предотвращаем ввод 'e'
                    }
                }}
                onChange={(e) => {
                    validateInputStake(e.target.value)
                }}
                onInput={(e) => {

                    console.log(e.target.value)

                    validateInputStake(e.target.value);
                }}

            />

            <div className={"create-user-btn-container"}>
                {createError.show
                    ?
                    <h3 className={"create-user-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-user-success-message"}>Мерчант {store.merchantToEditId ? "сохранён" : "добавлен"} успешно!</h3>
                    :
                    null
                }
                <button
                    className={"gradient-dark-button create-user-btn"}
                    onClick={() => createMerchant(merchantData)}
                >{store.merchantToEditId ? "Сохранить" : "Создать"}
                </button>
            </div>


        </div>
    );
};

export default MerchantCreationForm;