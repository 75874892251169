import $api from '../http';


class AuthService {
    static async login(login, password, browserId) {
        return $api.post('/login', {login, password, browserId})

    }

    static async createUser(login, password) {
        return $api.post('/create_user', {login, password})

    }

    static async logout(browserId) {

        //этот таймаут нужен потому что файрфокс не успевает выйти из учетки, не спрашивайте как я это понял
        setTimeout(() => {
            return $api.post(`/logout?browserId=${browserId}`)
        }, 1000)


    }


}


export default AuthService;