import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/card-creator.css"
import "../css/styles.css"
import Select from "react-select";
import InputMask from 'react-input-mask';
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";


const CardCreator = ({store}) => {

    const navigate = useNavigate()

    const [bankOptions, setBanksOptions] = useState([])

    useEffect(() => {

        getBanks()

        if(store.cardToEditId) {

            getCardToEdit()
        } else {
            store.setPageTitle("Создание карты")
        }


        return () => { store.setCardToEditId("") }

    }, [])


    const getBanks = async () => {
        const response = await $api.get('/get_banks')


        const optionsToRoll = response.data.map(bank => ({value: bank.code, label: bank.name}))

        setBanksOptions(optionsToRoll)

    }


    const getCardToEdit = async (cardId) => {
        store.setPageTitle("Редактирование карты")
        const response = await $api.get(`/get_card_by_id?cardId=${store.cardToEditId}`)
        console.log(response.data)
        setCardData(response.data)
    }



    const ruissianNamesOfFields = {
        'bank': "Банк",
        'number': "Номер",
        // 'deviceId': "ID устройства",
        'cardholder': "ФИО",
        'min': "Минимальная сумма",
        'max': "Максимальная сумма",
        'dayLimit': "Дневной лимит",
        'phone': "Телефон для СБП",
        'account': "Счёт",
        'trafficType': "Тип трафика",
    }


    const trafficOptions = [

        {value: "any", label: "Любой"},
        {value: "sbp", label: "Только по СБП"},
        {value: "card_number", label: "Только по номеру карты"},
        {value: "account_number", label: "Только по номеру счёта"}
    ]

    // const bankOptions = [
    //     { value: "AkBars", label: "Ак-Барс" },
    //     { value: "Alfa", label: "Альфа-банк" },
    //     { value: "BCSBank", label: "БКС банк" },
    //     { value: "BSPB", label: "БСПБ" },
    //     { value: "VTB", label: "ВТБ" },
    //     { value: "Gasprom", label: "Газпром" },
    //     { value: "DomRF", label: "ДомРФ" },
    //     { value: "Mbank", label: "Мбанк" },
    //     { value: "MKB", label: "МКБ" },
    //     { value: "Ozon", label: "Озон" },
    //     { value: "Primorye", label: "Приморье" },
    //     { value: "PSB", label: "ПСБ" },
    //     { value: "Raiffeisen", label: "Райффайзен" },
    //     { value: "Rosbank", label: "Росбанк" },
    //     { value: "RSHB", label: "РСХБ" },
    //     { value: "RSB", label: "Русский Стандарт" },
    //     { value: "Sber", label: "Сбер" },
    //     { value: "Solidarnost", label: "Солидарность" },
    //     { value: "Sovcom", label: "Совкомбанк" },
    //     { value: "Svoibank", label: "Свой банк" },
    //     { value: "Tinkoff", label: "Т-Банк (Тинькофф)" },
    //     { value: "UBRR", label: "УБРиР" },
    //     { value: "Uralsib", label: "Уралсиб" },
    //     { value: "ExpoBank", label: "ЭкспоБанк" },
    // ];


    function addDevidersToString(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    function formatPhoneNumber(phoneNumber) {
        // Проверим, что длина номера равна 11 символам
        if (phoneNumber.length !== 11) {
            throw new Error("Неверный формат номера");
        }

        // Разделим номер на части
        const countryCode = phoneNumber.slice(0, 1);  // +7
        const areaCode = phoneNumber.slice(1, 4);     // 956
        const firstPart = phoneNumber.slice(4, 7);    // 188
        const secondPart = phoneNumber.slice(7, 9);   // 95
        const thirdPart = phoneNumber.slice(9);       // 18

        // Соберем форматированный номер
        return `+${countryCode} (${areaCode}) ${firstPart} ${secondPart} ${thirdPart}`;
    }




    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")
    const [dataToShow, setDataToShow] = useState({})
    const [dataToShowInModal, setDataToShowInModal] = useState(null)

    const [cardData, setCardData] = useState({})
    const [createError, setCreateError] = useState({show: false, message: ""})

    const [showOk, setShowOk] = useState(false)


    const createRequest = async (cardData) => {

        try {

            // cardData.deviceId = cardData.deviceId.replace(/-/g, "")
            cardData.number = cardData.number.replace(/-/g, "")

            if(store.cardToEditId) {
                cardData._id = store.cardToEditId
            }



            const response = await $api.post('/create_card', {cardData: JSON.stringify(cardData)})

            console.log(response.data)

            if(response.data === "OK") {
                setShowOk(true)
                setCardData({
                    'bank': "",
                    'number': "",
                    // 'deviceId': "",
                    'cardholder': "",
                    'min': "",
                    'max': "",
                    'dayLimit': "",
                    'phone': "",
                    'account': "",
                    'trafficType': "",
                })

                if(store.cardToEditId) {
                    store.setCardToEditId("")
                    navigate('/card_viewer')
                }

                setTimeout(() => {
                    setShowOk(false)
                }, 5000)
            } else {
                setCreateError({show: true, message: response.data.message})
            }

        } catch (e) {
            console.log(e)
            setCreateError({show: true, message: "Ошибка создания карты"})
        }
    }


    const createCard = async (cardData) => {
        // Список обязательных полей
        const requiredFields = [
            'bank',
            'number',
            // 'deviceId',
            'cardholder',
            'min',
            'max',
            'dayLimit',
            'phone',
            // 'account',
            'trafficType'
        ];

        // Проверка на заполнение всех полей
        const allFieldsFilled = requiredFields.every(field => {
            return cardData[field] !== undefined && cardData[field] !== null && cardData[field] !== '';
        });

        if (allFieldsFilled) {
            // Если все поля заполнены

            if(cardData.number.includes("_")) {
                setCreateError({show: true, message: "Карта содержит неверное значение"})
                return
            }

            if(cardData.phone.includes("_")) {
                setCreateError({show: true, message: "Телефон содержит неверное значение"})
                return
            }

            // if(cardData.deviceId.includes("_") || cardData.deviceId.replace(/-/g, "").length !== 15) {
            //     setCreateError({show: true, message: "ID устройства содержит неверное значение"})
            //     return
            // }


            setModalMessage("Подтвердите данные карточки")

            console.log(cardData)

            const divElement = (
                <div className={"data-to-show-in-modal"}>

                    {Object.entries(cardData).map(([key, value]) => {
                    if(key === "min" || key === "max" || key === "dayLimit") {
                        value = addDevidersToString(value)
                    } else if(key === "phone") {
                        value = formatPhoneNumber(value)
                    }


                    return (
                        <div key={key}>
                            <strong>{ruissianNamesOfFields[key]}: </strong>
                            {value !== "any"
                                ?
                                value || "не указано"
                                :
                                trafficOptions.find(item => item.value === value).label
                            }
                        </div>
                    )})}
                </div>
            );


            setDataToShowInModal(divElement)

            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)


        } else {
            // Если какие-то поля не заполнены, выполняем другое действие
            // console.log(cardData.deviceId)
            // console.log(cardData.deviceId.length)
            setCreateError({show: true, message: "Заполнены не все поля"})
        }
    };



    return (
        <div className={"card-creator-wrapper"}>
            <Select
                placeholder={"Банк"}
                className={"card-creator-selector"}
                value={cardData.bank ?
                    { value: cardData.bank, label: bankOptions.find(option => option.value === cardData.bank)?.label } : ""
                }
                options={
                    bankOptions
                }
                onChange={(selected) => {
                    console.log(selected)
                    setCardData(prev => {
                        return {
                            ...prev,
                            bank: selected.label
                        }
                    })

                }}

            />

            <Select
                placeholder={"Тип трафика"}
                className={"card-creator-selector"}

                value={cardData.trafficType ?
                    { value: cardData.trafficType, label: trafficOptions.find(option => option.value === cardData.trafficType)?.label } : ""
                }
                options={
                    trafficOptions
                }
                onChange={(selected) => {
                    console.log(selected)
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            trafficType: selected.value
                        }
                    })

                }}
            />

            <InputMask
                className={"card-creator-input"}
                type={"text"}
                // alwaysShowMask={true}
                mask="9999-9999-9999-9999"
                maskChar="_"
                placeholder={"Номер карты"}
                value={cardData.number}
                onChange={(e) => {
                    setCardData(prev => {
                        setCreateError({show: false})
                        const value = e.target.value
                        return {
                            ...prev,
                            number: value
                        }
                    })
                }}
            />
            {/*<InputMask*/}
            {/*    className={"card-creator-input"}*/}
            {/*    mask='*****-*****-*****'*/}
            {/*    maskChar="_"*/}
            {/*    type={"text"}*/}
            {/*    placeholder={"ID устойства"}*/}
            {/*    value={cardData.deviceId}*/}
            {/*    onChange={(e) => {*/}
            {/*        console.log(e.target.value)*/}
            {/*        setCardData(prev => {*/}
            {/*            setCreateError({show: false})*/}
            {/*            return {*/}
            {/*                ...prev,*/}
            {/*                deviceId: e.target.value*/}
            {/*            }*/}
            {/*        })*/}
            {/*    }}*/}

            {/*/>*/}
            <input
                className={"card-creator-input"}
                type={"text"}
                placeholder={"Ф.И.О."}
                value={cardData.cardholder}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            cardholder: e.target.value
                        }
                    })
                }}
            />
            <input
                className={"card-creator-input"}
                type={"number"}
                placeholder={"Минимальная сумма"}
                value={cardData.min}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            min: e.target.value
                        }
                    })
                }}
            />
            <input
                className={"card-creator-input"}
                type={"number"}
                placeholder={"Максимальная сумма"}
                value={cardData.max}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            max: e.target.value
                        }
                    })
                }}
            />
            <input
                className={"card-creator-input"}
                type={"number"}
                placeholder={"Дневной лимит"}
                value={cardData.dayLimit}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            dayLimit: e.target.value
                        }
                    })
                }}
            />

            <InputMask
                className={"card-creator-input"}
                type={"text"}
                placeholder={"Телефон для СБП"}
                value={cardData.phone}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        const value = e.target.value.replace(/[-\s()+]/g, "");
                        return {
                            ...prev,
                            phone: value
                        }
                    })
                }}
                // alwaysShowMask={true}
                mask="+7 (\999)-999-9999"
                maskChar="_"


            />
            <input
                className={"card-creator-input"}
                type={"text"}
                placeholder={"Счёт"}
                value={cardData.account}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            account: e.target.value
                        }
                    })
                }}
            />


            <div className={"create-card-btn-container"}>
                {createError.show
                    ?
                    <h3 className={"create-card-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-card-success-message"}>Карта добавлена успешно!</h3>
                    :
                    null
                }

                <div className={"card-creator-btn-wrapper"}>
                    <button
                        className={"gradient-dark-button create-card-btn"}
                        onClick={() => createCard(cardData)}
                    >{store.cardToEditId ? "Сохранить" : "Создать"}</button>

                    {store.cardToEditId
                        ?
                        <button
                            className={"gradient-red-button create-card-btn"}
                            onClick={() => {
                                store.setCardToEditId("")
                                navigate("/card_viewer")
                            }}
                        >Отмена</button>
                        :
                        null
                    }
                </div>



            </div>

            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                action={() => {
                    createRequest(cardData)
                }}
                dataToShow={dataToShowInModal}
                message={modalMessage}
                purpose={"choice"}
            />
        </div>
    );
};

export default CardCreator;