import React from 'react';

const BanksTable = ({banks}) => {
    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Название</th>
                <th>Код</th>
                <th>Настройки</th>

            </tr>
            </thead>
            <tbody>
            {banks?.sort((a, b) => a.name.localeCompare(b.name)).map((bank, index) => {
                // const formattedDate = new Date(card.block_timestamp)
                //     .toLocaleString('ru-RU', options);

                return (
                    <tr
                        key={bank._id}
                        className={
                           index % 2 === 0
                                ?
                                'even-row'
                                :
                                'odd-row'
                        }
                    >
                        <td>{bank.name}</td>
                        <td>{bank.code}</td>
                        <td></td>


                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default BanksTable;