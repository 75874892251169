import axios from 'axios';

// export const API_URL = 'https://localhost:5000'
//export const API_URL = 'https://localhost:3030/api/v1'
//export const API_IP = 'localhost'


export const API_URL = 'https://gotham-trade.com:3030/api/v1'
export const API_IP = 'gotham-trade.com'


//создаем инстанс (экземпляр) аксиоса, то есть будем делать запросы ссылаясь на этот файл
const $api = axios.create({
    //вот это поле нужно для того чтобы куки автоматически цеплялись к запросу
    withCredentials: true,

    //по этому адресу инстанс будет слать запросы
    baseURL: API_URL,

})

//это интерцептор для нашего инстанса который вешает на каждый запрос заголовок
//Authorization с токеном пользователя
$api.interceptors.request.use((config) => {
    //колбэк принимает на вход конфиг инстанса,
    //у конфига все те же поля что и у инстанса, и в это поле из локального зранилища мы крепим токен
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
})


//это интерцептор для ответа (response)
//config это что то вроде настроек применяемых интерцептором, по дефолту они пустые, или некие стандартные
//для моего уровня знаний это черный ящик, поэтому и даю такое определение на основе логики
$api.interceptors.response.use((config) => {
    //если ответ приходит со статусом OK, 200 или тому подобное то ничего не делаем, возвращаем конфиг как есть
    return config;
}, async (error) => {
    //а вот если получен ответ с ошибкой, тогда интерцептор начинает выполнять свою функцию
    try {
        //это, как я понимаю снэпшот запроса на который прилетел ответ с ошибкой, сохраняем в переменную
        const originalRequest = error.config;
        //далее ряд условий которые все должны быть  соблюдены для запроса обновления токена
        // 1. если статус ошибки 401 и
        // 2. оригинальный запрос в принципе существует
        // 3. флаг того что это уже не первая попытка работы интерцептора на данном запросе имеет значение false
        if(error.response.status === 401 && error.config && !error.config._isRetry) {
            //ставим флаг в true, чтобы при повторном запуске интерцептора мы знали что это уже не первая попытка
            originalRequest._isRetry = true;
            //выполняем запрос на обновление токена
            console.log("Refresh init from index.js")
            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true});
            //кладем новый токен в локальное хранилище
            localStorage.setItem('token', response.data.accessToken);
            //повторяем исходный запрос, но теперь у него будет новый, живой токен.
            return $api.request(originalRequest)
        }

    } catch (e) {
        //если же у нас имеются какие то вопросики по авторизации, а-ля не выполнены три условия блока "если" выше
        //то сообщаем юзеру что он не авторизован и нужно бы ввести данные заново
        console.log('НЕ АВТОРИЗОВАН')

    }

    throw error;
})

export default $api;