import React, {useEffect, useState} from 'react';
import {generatePassword} from "./helper-functions";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import $api from "../../http";
import "../../css/user-creator.css"
import {toJS} from "mobx";

const TeamleadCreationForm = ({store, createError, setCreateError}) => {

    const navigate = useNavigate()


    const [showOk, setShowOk] = useState(false)

    const [teamleadData, setTeamleadData] = useState({})
    const [traders, setTraders] = useState([])
    const [merchants, setMerchants] = useState([])
    const [traderOptions, setTraderOptions] = useState([])
    const [merchantOptions, setMerchantOptions] = useState([])

    const getTraders = async () => {
        // setLoading(true)

        console.log(toJS(store.user.id))

        const response = await $api.get(`/get_users`)
        console.log(response.data)

        setTraders(response.data)
        // setLoading(false)
    }

    const getMerchants = async () => {
        // setLoading(true)

        console.log(toJS(store.user.id))

        const response = await $api.get(`/get_merchants`)
        console.log(response.data)

        setMerchants(response.data)
        // setLoading(false)
    }


    const getTeamleadToEdit = async (teamleadId) => {
        const response = await $api.get(`/get_teamlead_by_id?teamleadId=${teamleadId}`)
        console.log(response.data)
        setTeamleadData(prev => {
            setCreateError({show: false})
            return {
                ...prev,
                id: response.data.id,
                login: response.data.login,
                stake: response.data.stake,
                traders: response.data.traders,
                merchants: response.data.merchants,
                // tgUserName: response.data.tgUserName
            }
        })
    }


    useEffect(() => {

        getTraders()
        getMerchants()

        if(store.teamleadToEditId) {
            getTeamleadToEdit(store.teamleadToEditId)
        }


        return () => {
            store.setTeamleadToEditId("")
        }


    }, []);

    useEffect(() => {

        const optionsToSet = traders.map(trader => ({value: trader.id, label: trader.login}))
        setTraderOptions(optionsToSet)


    }, [traders])

    useEffect(() => {

        const optionsToSet = merchants.map(merchant => ({value: merchant._id, label: merchant.login}))
        setMerchantOptions(optionsToSet)


    }, [merchants])

    const createTeamlead = async (teamleadData) => {

        console.log(teamleadData)


        // Список обязательных полей
        const requiredFields = [
            'login',
            'stake',


        ];

        if(!store.teamleadToEditId) {
            requiredFields.push("password")
        }


        // Проверка на заполнение всех полей
        const allFieldsFilled = requiredFields.every(field => {
            console.log(field, teamleadData[field] !== undefined, teamleadData[field] !== null, teamleadData[field] !== '')
            return teamleadData[field] !== undefined && teamleadData[field] !== null && teamleadData[field] !== '';
        });

        if (allFieldsFilled) {
            // Если все поля заполнены

            console.log(teamleadData)
            console.log("Всё норм создаем юзера")
            try {
                const response = await $api.post('/create_teamlead', {teamleadData: JSON.stringify(teamleadData)})
                console.log(response.data)
                if(response.data === "OK") {
                    setShowOk(true)
                    setTeamleadData({
                        'login': "",
                        'password': "",
                        'stake': "",

                    })
                    setTimeout(() => {
                        setShowOk(false)
                        store.setTeamleadToEditId("")
                        navigate('/teamlead_viewer')
                    }, store.teamleadToEditId ? 2000 : 5000)
                } else {
                    setCreateError({show: true, message: response.data.message})
                }

            } catch (e) {
                console.log(e)
                setCreateError({show: true, message: "Ошибка создания юзера"})
            }



        } else {
            // Если какие-то поля не заполнены, выполняем другое действие
            setCreateError({show: true, message: "Заполнены не все поля"})
        }
    };

    function validateInputStake(input) {

        // Регулярное выражение для чисел от 0.1 до 10, включая дробные
        const validPattern = /^(1(\.0{1,2})?|0\.(0[5-9]|[1-9]\d?))$/;
        const valid = validPattern.test(input);


        if (valid) {
            setTeamleadData(prev => {
                setCreateError({ show: false });
                return {
                    ...prev,
                    stake: input !== "" ? parseFloat(input.toString().replace(",", ".")) : "" // Поле, которое обновляем
                };
            });
        } else {
            setCreateError({ show: true, message: "Введите число от 0.05 до 1" });
        }
    }


    function validateInputLogin(input) {

        const validPattern = /^[A-Za-z0-9]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setTeamleadData(prev => {
                setCreateError({show: false})
                return {
                    ...prev,
                    login: input
                }
            })
        }
    }

    // function validateTgUserName(input) {
    //
    //     const validPattern = /^[A-Za-z0-9@_]*$/;
    //     const valid = validPattern.test(input)
    //
    //     if(valid) {
    //         setTeamleadData(prev => {
    //             setCreateError({show: false})
    //             return {
    //                 ...prev,
    //                 tgUserName: input
    //             }
    //         })
    //     }
    // }



    return (
        <div className={"support-creator-wrapper"}>
            <h1 className={"component-title"}>{store.teamleadToEditId ? "Редактируем" : "Создаём"} тимлида</h1>

            <input
                className={"user-creator-input"}
                type={"text"}
                pattern="[A-Za-z0-9]+"
                placeholder={"Имя (login)"}
                value={teamleadData.login}
                onChange={(e) => {
                    validateInputLogin(e.target.value)

                }}
            />

            <div className={"user-creator-password-input-n-generator-container"}>
                <input
                    readOnly={true}
                    className={"user-creator-input password-input"}
                    type={"text"}
                    placeholder={"password"}
                    value={teamleadData.password}

                />
                <button
                    className={"gradient-dark-button pass-gen-btn"}
                    onClick={() => {
                        const freshPass = generatePassword()
                        setTeamleadData(prev => {
                            setCreateError({show: false})
                            return {
                                ...prev,
                                password: freshPass
                            }
                        })
                    }}
                >{store.supportToEditId ? "New Password" : "Generate"}
                </button>
            </div>

            <label
                className={"password-warning red-for-blue"}
            >{teamleadData.password
                ?
                `Скопируйте пароль, после ${store.teamleadToEditId ? 'сохранения' : 'создания'} тимлида его больше нельзя будет увидеть`
                :
                null
            }
            </label>

            <input
                className={"user-creator-input"}
                type={"number"}
                placeholder={"Ставка (%)"}
                value={teamleadData.stake}
                onChange={(e) => {
                    validateInputStake(e.target.value)
                }}

            />

            <Select
                placeholder={"Трейдеры"}
                className={"card-creator-selector"}

                value={teamleadData.traders?.map(traderId => ({
                    value: traderId,
                    label: traderOptions.find(option => option.value === traderId)?.label,
                })) || []}
                options={
                    traderOptions
                }
                isMulti={true}
                onChange={(selected) => {

                    const selectedValues = selected.map(option => option.value);

                    setTeamleadData(prev => {
                        setCreateError({ show: false });
                        return {
                            ...prev,
                            traders: selectedValues, // Сохраняем массив значений
                        };
                    });
                }}
            />


            <Select
                placeholder={"Мерчанты"}
                className={"card-creator-selector"}

                value={teamleadData.merchants?.map(merchantId => ({
                    value: merchantId,
                    label: merchantOptions.find(option => option.value === merchantId)?.label,
                })) || []}
                options={
                    merchantOptions
                }
                isMulti={true}
                onChange={(selected) => {


                    console.log(selected)

                    const selectedValues = selected.map(option => option.value);


                    setTeamleadData(prev => {
                        setCreateError({ show: false });
                        return {
                            ...prev,
                            merchants: selectedValues, // Сохраняем массив значений
                        };
                    });
                }}
            />


            {/*<input*/}
            {/*    className={"user-creator-input"}*/}
            {/*    type={"text"}*/}
            {/*    pattern="[A-Za-z0-9@_]+"*/}
            {/*    placeholder={"Ник в телеграме"}*/}
            {/*    value={teamleadData.tgUserName}*/}
            {/*    onChange={(e) => {*/}
            {/*        validateTgUserName(e.target.value)*/}

            {/*    }}*/}
            {/*/>*/}


            <div className={"create-user-btn-container"}>
                {createError.show
                    ?
                    <h3 className={"create-user-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-user-success-message"}>Пользователь {store.teamleadToEditId ? "сохранён" : "добавлен"} успешно!</h3>
                    :
                    null
                }
                <button
                    className={"gradient-dark-button create-user-btn"}
                    onClick={() => createTeamlead(teamleadData)}
                >{store.teamleadToEditId ? "Сохранить" : "Создать"}
                </button>
            </div>


        </div>
    );
};

export default TeamleadCreationForm;