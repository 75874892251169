import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/card-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";

const CardViewer = ({store, traders, updatedDevice}) => {

    const navigate = useNavigate()

    const [activeModalInfo, setActiveModalInfo] = useState("no")
    const [activeModalConfirm, setActiveModalConfirm] = useState("no")
    const [activeModalQr, setActiveModalQr] = useState("no")
    const [qrData, setQrData] = useState("")
    const [qrName, setQrName] = useState("")

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [processing, setProcessing] = useState({status: false, index: null})

    const [cardToDelete, setCardToDelete] = useState()


    const [filter, setFilter] = useState({})



    const getCards = async (filter) => {

        filter = filter ? encodeURIComponent(JSON.stringify(filter)) : ""

        const response = await $api.get(`/get_cards?filter=${filter}`)
        console.log(response.data)
        setCards(response.data)
    }

    const deleteCard = async (cardId) => {


        try {
            const response = await $api.delete(`/delete_card?cardId=${cardId}`)
            console.log(response.data)
            if(response.data === "OK") {

                setModalMessage(`Карта удалена!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)

                setCards(prev => {
                    const newCardsState = prev.filter(card => card._id !== cardId)
                    return newCardsState
                })
            } else {
                setModalMessage(`Это не ваша карта, удалить ее нельзя!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)
            }
        } catch (e) {
            setModalMessage(e.message)
            setActiveModalInfo("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
        }



    }


    const changeCardStatus = async (cardId, index) => {




        try {
            setProcessing({status: true, index})



            const response = await $api.get(`/change_card_status?cardId=${cardId}`)
            console.log(response.data)


            // Получаем обновленный объект карты из ответа
            const updatedCard = response.data;

            // Обновляем состояние карт
            setCards(prevCards =>
                prevCards.map(card =>
                    card._id === updatedCard._id ? updatedCard : card
                )
            );
            setProcessing({status: false, index})

        } catch (e) {
            setModalMessage("Ошибка смены статуса карты\n" + e.message + "\n зовите админа")
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
            setProcessing({status: false, index})
        }


    }



    const [cards, setCards] = useState([])


    useEffect(() => {
        store.setPageTitle("Все карты")

        if(store.user.roles.includes("support") && Object.keys(filter).length) {
            getCards(filter)
        } else {
            getCards()
        }

    }, [filter]);


    useEffect(() => {


        if(store.user.roles.includes("support")) {

            if(updatedDevice.user === filter.traderId || updatedDevice.isCard) {
                console.log(updatedDevice)

                setCards(prev => {
                    const newCardsState = prev?.map(card => {
                        console.log(card)
                        if(card.device?._id === updatedDevice?._id) {
                            card.device.online = updatedDevice.online
                            card.device.workStatus = updatedDevice.workStatus
                        } else if(card._id === updatedDevice?._id) {
                            return updatedDevice
                        }

                        return card

                    })

                    return newCardsState
                })

            }

        }



    }, [updatedDevice, filter, store.user])



    return (
        <div className={"card-viewer-wrapper"}>

            {store.user.roles.includes("support")
                ?
                <div className={"order-viewer-merchant-sort-wrapper"}>

                    {/*<select*/}

                    {/*    value={filter?.status}*/}
                    {/*    onChange={(e) => {*/}

                    {/*        setAppeals([])*/}
                    {/*        setProcessing(true)*/}
                    {/*        setSkipCounter(0);*/}

                    {/*        setFilter(prev => ({*/}
                    {/*            ...prev,*/}
                    {/*            status: e.target.value*/}
                    {/*        }))*/}


                    {/*        setTimeout(() => {*/}
                    {/*            setStopFetching(false)*/}
                    {/*            setFetching(true)*/}
                    {/*        }, 1000)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <option value={"pending"}>Открытые</option>*/}
                    {/*    <option value={"closed"}>Закрытые</option>*/}

                    {/*</select>*/}

                    <select
                        value={filter.trader}
                        onChange={(e) => {

                            // setAppeals([])
                            // setProcessing(true)
                            // setSkipCounter(0);

                            setFilter(prev => ({
                                ...prev,
                                traderId: e.target.value
                            }))

                            setTimeout(() => {
                                // setStopFetching(false)
                                // setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Чьи карты будем смотреть?</option>
                        {traders.map(trader => <option id={trader.id} value={trader.id}>{trader.login}</option>)}

                    </select>

                    {/*<button*/}
                    {/*    className={"gradient-dark-button"}*/}
                    {/*    onClick={() => {*/}

                    {/*        setAppeals([])*/}
                    {/*        setProcessing(true)*/}
                    {/*        setSkipCounter(0);*/}

                    {/*        setFilter({status: "pending", onlyMine: store.user.id, startDate: "", stopDate: ""})*/}


                    {/*        setTimeout(() => {*/}
                    {/*            setStopFetching(false)*/}
                    {/*            setFetching(true)*/}
                    {/*        }, 1000)*/}
                    {/*    }}*/}
                    {/*>Сброс*/}
                    {/*</button>*/}


                </div>
                :
                null
            }


            <table className="transaction-table">
                <thead>
                <tr>
                    <th>ФИО</th>
                    {store.user.roles.includes("trader")
                        ?
                        <th>Токен</th>
                        :
                        null
                    }

                    <th>Банк</th>
                    <th>Номер</th>
                    {store.user.roles.includes("support")
                        ?
                        <th>Устройство</th>
                        :
                        null
                    }
                    <th className={"fixed-table-width"}>Активность</th>
                    {store.user.roles.includes("support")
                        ?
                        <th>Управление</th>
                        :
                        null
                    }
                    {store.user.roles.includes("trader")
                        ?
                        <th className={"fixed-table-width"}>Изменение</th>
                        :
                        null
                    }
                    {store.user.roles.includes("trader")
                        ?
                        <th>Удаление</th>
                        :
                        null
                    }
                </tr>
                </thead>
                <tbody>
                {cards.map((card, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    console.log(card)

                    return (
                        <tr key={card._id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{card.cardholder}</td>
                            {store.user.roles.includes("trader")
                                ?
                                <td>
                                    {card.token
                                        ?
                                        <button
                                            className="gradient-orange-button qr-token-btn"
                                            onClick={() => {
                                                setQrData(card.token?.token)
                                                setQrName(card.cardholder)
                                                setActiveModalQr("progress")
                                                setTimeout(() => {
                                                    setActiveModalQr("active")
                                                }, 100)
                                            }}
                                        >
                                            QR-код
                                        </button>
                                        :
                                        null
                                    }
                                    <span>{card.token?.token}</span>

                                </td>
                                :
                                null
                            }
                            <td>{card.bank}</td>
                            <td title={card.number}>{"*" + card.number.slice(-4)}</td>
                            {store.user.roles.includes("support")
                                ?
                                <td>
                                    {card.device?.online && card.device?.workStatus
                                        ?
                                        <div className={"green-bold"}>OnLine</div>
                                        :
                                        !card.device?.workStatus
                                            ?
                                            card.device
                                                ?
                                                <div className={"red-bold"}>Отключено трейдером</div>
                                                :
                                                <div className={"red-bold"}>Не привязано</div>
                                            :
                                            <div className={"red-bold"}>OffLine</div>
                                    }
                                </td>
                                :
                                null
                            }
                            <td>
                                {store.user.roles.includes("trader")
                                    ?
                                    card.stopByFails
                                        ?
                                        "⚠️ Остановлена системой"
                                        :
                                        card.active
                                            ?
                                            <button
                                                className={"gradient-red-button"}
                                                onClick={() => {

                                                    changeCardStatus(card._id, index)

                                                }}
                                            >Отключить {
                                                processing.status && processing.index === index
                                                    ?
                                                    <img src={require('../img/loading.gif')} height={"15px"}
                                                         className={"margin-left-20"}/>
                                                    :
                                                    null
                                            }
                                            </button>
                                            :
                                            <button
                                                className={"gradient-green-button card-status-switch-btn"}
                                                onClick={() => {

                                                    changeCardStatus(card._id, index)
                                                }}
                                            >Включить {processing.status && processing.index === index ?
                                                <img src={require('../img/loading.gif')} height={"15px"}
                                                     className={"margin-left-20"}/> : null}</button>
                                    :
                                    card.active && !card.stopByFails
                                        ?
                                        "Активна"
                                        :
                                        card.stopByFails
                                            ?
                                            "⚠️ Остановлена системой"
                                            :
                                            "⚠️ Остановлена трейдером"

                                }

                            </td>
                            {store.user.roles.includes("trader")
                                ?
                                <td>
                                    <button
                                        className={"gradient-orange-button"}
                                        onClick={() => {
                                            store.setCardToEditId(card._id)
                                            navigate('/create_card')

                                        }}
                                    >Редактировать {
                                        processing.status && processing.index === index
                                            ?
                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                 className={"margin-left-20"}/>
                                            :
                                            null
                                    }
                                    </button>
                                </td>
                                :
                                null
                            }

                            {store.user.roles.includes("trader")
                                ?
                                <td>
                                    <button
                                        className={"gradient-dark-button"}
                                        onClick={() => {

                                            setModalMessage(`Вы действительно хотите удалить карту ${card.number}\n${card.cardholder}`)
                                            setCardToDelete(card._id)
                                            setActiveModalConfirm("progress")
                                            setTimeout(() => {
                                                setActiveModalConfirm("active")
                                            }, 100)
                                        }}
                                    >Удалить
                                    </button>
                                </td>
                                :
                                null
                            }
                            {store.user.roles.includes("support")
                                ?
                                <td>
                                    {!card.stopByFails
                                        ?
                                        <button
                                            className={"gradient-red-button"}
                                            onClick={() => {

                                                changeCardStatus(card._id, index)

                                            }}
                                        >Отключить {
                                            processing.status && processing.index === index
                                                ?
                                                <img src={require('../img/loading.gif')} height={"15px"}
                                                     className={"margin-left-20"}/>
                                                :
                                                null
                                        }
                                        </button>
                                        :
                                        <button
                                            className={"gradient-green-button card-status-switch-btn"}
                                            onClick={() => {

                                                changeCardStatus(card._id, index)
                                            }}
                                        >Включить {processing.status && processing.index === index ?
                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                 className={"margin-left-20"}/> : null}</button>
                                    }
                                </td>
                                :
                                null
                            }


                        </tr>
                    );
                })}
                </tbody>
            </table>


            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />
            <ModalDialog
                active={activeModalQr}
                setActive={setActiveModalQr}
                qrcode={qrData}
                message={qrName}
                purpose={"info"}
            />

            <ModalDialog
                active={activeModalConfirm}
                setActive={setActiveModalConfirm}
                message={modalMessage}
                purpose={"choice"}
                action={() => deleteCard(cardToDelete)}

            />

            <ModalDialog
                active={activeModalInfo}
                setActive={setActiveModalInfo}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />
        </div>
    );
};

export default CardViewer;