import React, {useState} from 'react';
import $api from "../../http";
import ModalDialog from "../ModalDialog";
import ModalImgViewer from "./ModalImgViewer";
import {addDevidersToString} from "./helper-functions";

const TraderAppealTable = ({appeals, processing, closeAppeal}) => {

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")
    const [activeNoPayModal, setActiveNoPayModal] = useState("no")
    const [activeActualAmountModal, setActiveActualAmountModal] = useState("no")

    const [activeTraderConfirmModal, setActiveTraderConfirmModal] = useState("no")

    const [activeImgModal, setActiveImgModal] = useState("no")
    const [imgSrc, setImgSrc] = useState("")

    const [currentAppeal, setCurrentAppeal] = useState()
    const [actualAmount, setActualAmount] = useState()


    function validateInputActualAmount(input) {
        // Регулярное выражение для чисел, начинающихся не с 0
        // const validPattern = /^(10|[0-9](\.\d+)?|0\.[1-9]+)?$/;
        const validPattern = /^$|^[1-9]\d*(?:[.,]\d+)?$/;

        const valid = validPattern.test(input);

        if (valid) {
            setActualAmount(input);
        }
    }



    const noPayment = async (appealId, scam) => {
        const response = await $api.post('/appeal_no_payment', {appealId, scam})

        console.log(response.data)


    }


    const setActualAmountToAppeal = async (appealId, actualAmount) => {


        const response = await $api.post('/appeal_set_actual_amount', {appealId, actualAmount})

        console.log(response.data)


    }



    return (

        appeals.length
            ?
            <div className={"transaction-table-wrapper"}>
                <table className="transaction-table">
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Номер</th>
                        <th>Заявка</th>
                        {/*<th>Мерчант</th>*/}
                        {/*<th>Трейдер</th>*/}
                        <th>Сумма (руб)</th>
                        <th>Решение</th>
                        <th>Инфо</th>
                        <th>Актуальная сумма</th>
                        <th>Чек</th>

                        <th className={"fixed-table-width"}>Управление</th>

                    </tr>
                    </thead>
                    <tbody>
                    {appeals.length
                        ?
                        appeals?.map((appeal, index) => {
                            // const formattedDate = new Date(card.block_timestamp)
                            //     .toLocaleString('ru-RU', options);

                            return (
                                <tr
                                    key={appeal._id}
                                    className={
                                        index % 2 === 0
                                            ?
                                            'even-row'
                                            :
                                            'odd-row'
                                    }
                                >
                                    <td>{new Date(appeal.created_at).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</td>
                                    <td>{appeal.number}</td>
                                    <td>{appeal.order?.number}</td>
                                    {/*<td>{appeal.merchant?.login}</td>*/}
                                    {/*<td>{appeal.trader?.login}</td>*/}
                                    <td>{appeal.amount}</td>
                                    <td>{appeal.resolution}</td>
                                    <td>{appeal.traderDescription}</td>
                                    <td>{appeal.actualAmount}</td>
                                    <td>
                                        {appeal.receipt
                                            ?
                                            <button
                                                className={"gradient-orange-button"}
                                                onClick={() => {
                                                    setActiveImgModal("progress")
                                                    setTimeout(() => {
                                                        setActiveImgModal("active")
                                                    }, 100)
                                                    setImgSrc(appeal.receipt)
                                                }}
                                            >
                                                Показать
                                            </button>
                                            :
                                            null
                                        }
                                    </td>
                                    <td>
                                        {appeal.status !== "closed" && !appeal.traderDescription
                                            ?
                                            <button
                                                className={"gradient-orange-button"}
                                                onClick={() => {

                                                    setCurrentAppeal(appeal)
                                                    setActiveModal("progress")
                                                    setTimeout(() => {
                                                        setActiveModal("active")
                                                    }, 100)

                                                }}
                                            >
                                                Реакция
                                            </button>
                                            :
                                            null
                                        }
                                    </td>

                                </tr>
                            );
                        })
                        :
                        null
                    }
                    </tbody>


                </table>
                <ModalDialog
                    active={activeModal}
                    setActive={setActiveModal}
                    action={() => {
                        setActiveTraderConfirmModal("progress")
                        setTimeout(() => {
                            setActiveTraderConfirmModal("active")
                        }, 100)
                    }}
                    action2={() => {
                        setActiveNoPayModal("progress")
                        setTimeout(() => {
                            setActiveNoPayModal("active")
                        }, 100)
                    }}
                    message={`Есть платёж на ${addDevidersToString(currentAppeal?.amount)} р. по заявке №${currentAppeal?.order.number} ?`}
                    purpose={"pay_choice"}
                    buttonText2={"Нет"}
                />
                <ModalDialog
                    active={activeTraderConfirmModal}
                    setActive={setActiveTraderConfirmModal}
                    action={() => {
                        closeAppeal(currentAppeal._id)
                    }}
                    message={`Вы уверены что хотите закрыть апелляцию #${currentAppeal?.number} в пользу клиента?`}
                    purpose={"choice"}

                />
                <ModalDialog
                    active={activeNoPayModal}
                    setActive={setActiveNoPayModal}
                    action={() => {
                        noPayment(currentAppeal._id)
                    }}
                    action2={() => {

                        setActiveNoPayModal("progress")
                        setTimeout(() => {
                            setActiveNoPayModal("no")
                        }, 100)

                        setActiveActualAmountModal("progress")
                        setTimeout(() => {
                            setActiveActualAmountModal("active")
                        }, 100)
                    }}
                    action3={() => noPayment(currentAppeal._id, true)}
                    message={`Какой из этих случаев ваш?`}
                    purpose={"no_pay_choice"}

                />
                <ModalImgViewer
                    active={activeImgModal}
                    setActive={setActiveImgModal}
                    imgSrc={imgSrc?.replace(/\\/g, "/")}
                    setImgSrc={setImgSrc}
                />
                <ModalDialog
                    active={activeActualAmountModal}
                    setActive={setActiveActualAmountModal}
                    purpose={"input"}
                    inputType={"number"}
                    message={`Введите актуальную сумму для апелляции #${currentAppeal?.number}`}
                    inputValue={actualAmount}
                    setInputValue={validateInputActualAmount}
                    buttonText1={"Подтвердить"}
                    action={() => setActualAmountToAppeal(currentAppeal._id, actualAmount)}

                />

            </div>
            :
            processing
                ?
                <img src={require('../../img/loading.gif')} height={"40px"}
                     className={"margin-left-20"}/>
                :
                <h2>Нет ничего соответствующего вашему запросу</h2>






    );
};

export default TraderAppealTable;