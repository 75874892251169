


function containsLink(text) {
    const linkRegex = /(https?:\/\/[^\s]+)/;
    return linkRegex.test(text);
}

function processMessageText(text) {
    if (text) {
        console.log(text);
        // Заменяем символы новой строки на тег <br>
        const formattedText = text.replace(/\n/g, '<br/>');

        // Создаем строку с ссылками, если они присутствуют
        const messageContent = formattedText.replace(
            /(https?:\/\/[^\s]+)/g,
            (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
        );

        // Проверяем, содержит ли отформатированный текст ссылку
        if (containsLink(formattedText)) {
            return (
                <div
                    className={"text-ws-container"}
                    dangerouslySetInnerHTML={{ __html: messageContent }}
                />
            );
        }

        const main_array = formattedText.split(" ");
        let decision = false;
        let container = [];

        for (let piece of main_array) {
            if (piece.length > 25) {
                decision = true;
                break;
            }
        }

        if (decision) {
            const chunkSize = 20;

            function splitWord(text, size) {
                const chunks = [];
                for (let i = 0; i < text.length; i += size) {
                    chunks.push(text.slice(i, i + size));
                }
                return chunks;
            }

            const wordChunks = splitWord(formattedText, chunkSize);
            for (let chunk of wordChunks) {
                container.push(
                    <span key={chunk}>
                        {chunk}<wbr />
                    </span>
                );
            }
            return container;
        } else {
            return (
                <div
                    className={"no-click-to-go"}
                    dangerouslySetInnerHTML={{ __html: formattedText }}
                />
            );
        }
    }
}

function textProcessor(text) {
    if (text) {
        // Обработка ссылок
        if (containsLink(text)) {
            const messageContent = text.replace(
                /(https?:\/\/[^\s]+)/g,
                (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
            );

            return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
        }

        // Обработка переходов на новую строку
        const lines = text.split("\n");
        const formattedText = lines.map((line, index) => <p className={"p-from-text-processor"} key={index}>{line}</p>);

        return formattedText;
    } else {
        return text;
    }
}

//добавляет дефисы разделители между блоками по 5 символов
function addHyphens(str) {

    return str?.replace(/(.{5})/g, "$1-").slice(0, -1);
}


function isToday(date) {

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const MSK_OFFSET = 3 * 60 * 60 * 1000; // Смещение для МСК (3 часа в миллисекундах)
    const today = new Date();

    // Преобразуем обе даты в московское время
    // const moscowDate = new Date(date.getTime() + MSK_OFFSET);
    // const moscowToday = new Date(today.getTime() + MSK_OFFSET);



    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
}


function generatePassword() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
    const passwordLength = Math.floor(Math.random() * (20 - 15 + 1)) + 15; // Рандомная длина от 15 до 20 символов
    let password = '';

    for (let i = 0; i < passwordLength; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        password += chars[randomIndex];
    }

    // setUserData(prev => {
    //     setCreateError({show: false})
    //     return {
    //         ...prev,
    //         password: password
    //     }
    // })

    return password;
}



function throttle(func, delay) {
    let timerId;
    let lastExecTime = 0;

    return function (...args) {
        const currentTime = Date.now();

        if (currentTime - lastExecTime > delay) {
            func.apply(this, args);
            lastExecTime = currentTime;
        } else {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(this, args);
                lastExecTime = currentTime;
            }, delay);
        }
    };
}

function isSameDay(date1, date2) {

    if (!date2) return true

    date2 = new Date(date2)

    // Приводим дату ко времени МСК, создавая новые объекты с учётом временного смещения (GMT+3)
    const moscowOffset = 3 * 60 * 60 * 1000; // Смещение для МСК в миллисекундах


    const mskDate1 = new Date(date1.getTime() + moscowOffset);
    const mskDate2 = new Date(date2.getTime() + moscowOffset);

    return (
        mskDate1.getUTCFullYear() === mskDate2.getUTCFullYear() &&
        mskDate1.getUTCMonth() === mskDate2.getUTCMonth() &&
        mskDate1.getUTCDate() === mskDate2.getUTCDate()
    );
}


function removeDuplicatesById(array) {
    const seenIds = new Set();
    return array.filter(item => {
        if (seenIds.has(item.id)) {
            return false; // Дубликат, не включаем в результат
        } else {
            seenIds.add(item.id);
            return true; // Уникальный элемент, включаем в результат
        }
    });
}

function addDevidersToString(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}



export {
    processMessageText, textProcessor, containsLink,
    addHyphens, isToday, generatePassword, throttle,
    isSameDay, removeDuplicatesById, addDevidersToString
}