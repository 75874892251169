import React, {useEffect, useState} from 'react';
import "../css/stats.css"
import Plate from "./Plate";
import Chart from "./LineChart";
import CrowdImg from "../img/crowd.svg"
import RubleImg from "../img/ruble.svg"
import DollarImg from "../img/dollar.svg"
import $api from "../http";
import axios from "axios";
import {isSameDay, isToday} from "./helpers/helper-functions";

const Statistics = ({store, freshStatistics}) => {

    const [ordersData, setOrdersData] = useState([])
    const [usdtBalance, setUsdtBalance] = useState(0)
    const [todaysOrdersCounter, setTodaysOrdersCounter] = useState(0)
    // const [todaysTurnoverRub, setTodaysTurnOverRub] = useState(0)
    const [todaysTurnoverUsdt, setTodaysTurnOverUsdt] = useState(0)
    const [todaysProfitUsdt, setTodaysProfitUsdt] = useState(0)
    const [tableData, setTableData] = useState([])
    const [actualUsdtPrice, setActualUsdtPrice] = useState(0)


    const startingTurnOverSwitch = localStorage.getItem("startingTurnOverSwitch") === "true"
    const startingBalanceSwitch = localStorage.getItem("startingBalanceSwitch") === "true"
    const startingProfitSwitch = localStorage.getItem("startingProfitSwitch") === "true"



    const pageOpenDate = new Date()


    console.log(startingTurnOverSwitch)
    console.log(typeof startingTurnOverSwitch)

    function randomAmount(signs) {
        return Math.floor(Math.random() * 1000 * signs)
    }


    function countRequestsByHour(data) {
        // Создаем массив для хранения количества запросов по каждому часу
        const hoursCount = Array.from({ length: 24 }, (_, i) => ({
            hour: `${i.toString().padStart(2, '0')}:00`,
            requests: 0,
        }));

        // Проходим по каждому объекту и увеличиваем счетчик для соответствующего часа
        data.forEach(order => {
            const openDate = new Date(order.openDate);
            // Преобразуем в московское время (UTC+3)
            const moscowTime = new Date(openDate.setHours(openDate.getUTCHours() + 3));
            const hour = moscowTime.getHours(); // Получаем московский час

            hoursCount[hour].requests += 1;
        });

        return hoursCount;
    }


    const getBalance = async () => {
        const response = await $api.get(`/get_balance_from_db?userId=${store.user.id}`)
        console.log(response.data)

        setOrdersData(response.data.todaysOrders)

        setUsdtBalance(response.data.usdtBalance.toFixed(2))



        const usdtTraderProfitValue = response.data.todaysOrders.reduce((acc, current) => {

            acc = acc + current.traderProfit
            return acc

        }, 0)

        setTodaysProfitUsdt(usdtTraderProfitValue)

        setTodaysOrdersCounter(response.data.todaysOrders.length)


        const usdtTraderTurnoverValue = response.data.todaysOrders.reduce((acc, current) => {

            acc = acc + current.usdtAmount
            return acc

        }, 0)


        setTodaysTurnOverUsdt(usdtTraderTurnoverValue)



    }

    const getActualUsdtPrice = async () => {
        // const response = await axios.get('https://api.binance.com/api/v3/ticker/price?symbol=USDTRUB')

        // setActualUsdtPrice(response.data.price)
        const response = await $api.get('/get_ustd_price')

        console.log(response.data)
        console.log(parseFloat(response.data.price))

        setActualUsdtPrice(response.data)
    }


    useEffect(() => {
        store.setPageTitle(`Статистика за ${new Date().toLocaleDateString()}`)
        getBalance()
        getActualUsdtPrice()
    }, [])


    useEffect(() => {

        if(Object.keys(freshStatistics).length) {


            console.log(freshStatistics)


            if(isSameDay(pageOpenDate, freshStatistics.date)) {

                if(freshStatistics.freshOrderData) {
                    setOrdersData(prev => [...prev, freshStatistics.freshOrderData])
                }

                if(freshStatistics.balance) {
                    setUsdtBalance(freshStatistics.balance)
                }
                if(freshStatistics.profit) {
                    setTodaysProfitUsdt(prev => {
                        console.log("SettingProfir to - ", prev + freshStatistics.profit)
                        return prev + freshStatistics.profit
                    })
                }
                if(freshStatistics.currentOrderUsdtAmount) {
                    setTodaysTurnOverUsdt(prev => prev + freshStatistics.currentOrderUsdtAmount)
                }
                if(freshStatistics.currentOrderUsdtAmount) {
                    setTodaysOrdersCounter(prev => prev + 1)
                }

            } else {
                getBalance()
                getActualUsdtPrice()

            }



        }


    }, [freshStatistics])


    useEffect(() => {

        setTableData(countRequestsByHour(ordersData))


    }, [ordersData])


    return (
        <div className={"stats-wrapper"}>
            <Plate
                plateTitle={"Заявки"}
                plateImage={CrowdImg}
                plateAmount={todaysOrdersCounter}
            />
            <Plate
                plateTitle={"Профит"}
                plateImage={RubleImg}
                plateImageAlt={DollarImg}
                plateAmount={todaysProfitUsdt}
                actualUstdPrice={actualUsdtPrice}
                switcher={true}
                switcherPosition={startingProfitSwitch}
                localStorageItem={"startingProfitSwitch"}
            />
            <Plate
                plateTitle={"Оборот"}
                plateImage={RubleImg}
                plateImageAlt={DollarImg}
                plateAmount={todaysTurnoverUsdt}
                actualUstdPrice={actualUsdtPrice}
                switcher={true}
                switcherPosition={startingTurnOverSwitch}
                localStorageItem={"startingTurnOverSwitch"}
            />
            <Plate
                plateTitle={"Баланс"}
                plateImage={RubleImg}
                plateImageAlt={DollarImg}
                actualUstdPrice={actualUsdtPrice}
                plateAmount={usdtBalance}
                switcher={true}
                switcherPosition={startingBalanceSwitch}
                localStorageItem={"startingBalanceSwitch"}
            />
            <Chart data={tableData}/>
        </div>
    );
};

export default Statistics;