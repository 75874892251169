import React from 'react';
import "../css/modal-dialog.css"
import {processMessageText, textProcessor} from "./helpers/helper-functions";
import {QRCodeSVG} from 'qrcode.react';


const ModalDialog = ({
                         active, setActive, message,
                         action, action2, action3, purpose, dataToShow, buttonText1, buttonText2, qrcode,
                         inputPlaceHolder, inputValue, setInputValue, inputType, setWinner, setModalMessage,
                         appealToClose, inputPlaceHolder2, inputValue2, setInputValue2, input2, inputType2
}) => {
    return (
        <div className={active === "active"
            ?
            "modal active"
            :
            active === "progress" ? "modal" : "none"}
             onClick={() => {
                 setActive("progress")
                 setTimeout(() => {
                     // setTimerRelease(false)
                     // setTimerSeconds(seconds ?? 10)
                     setActive("no")
                 }, 300)
             }}
        >
            <div className={active === "active"
                ?
                "modal-content active"
                :
                active === "progress" ? "modal-content" : "none"}
                 onClick={(e) => e.stopPropagation()}>

                <div className={"modal-data-wrapper"}>
                    {qrcode
                        ?
                        <div className={"qr-token-wrapper"}>
                            <QRCodeSVG className={"qr-token-elemetn"} value={qrcode}/>
                        </div>
                        :
                        null
                    }
                    <span className={"modal-message"}>{textProcessor(message)}</span>
                    {dataToShow
                        ?
                        dataToShow
                        :
                        null
                    }
                    {purpose === "input"
                        ?
                        <div className={"modal-input-container"}>
                            <input
                                type={inputType}
                                className={"modal-input"}
                                placeholder={inputPlaceHolder}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />

                            {input2
                                ?
                                <input
                                    type={inputType2}
                                    className={"modal-input"}
                                    placeholder={inputPlaceHolder2}
                                    value={inputValue2}
                                    onChange={(e) => setInputValue2(e.target.value)}
                                />
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                </div>
                <div className={"decision-btn-container"}>
                    {purpose === "choice" || purpose === "input" || purpose === "winner" || purpose === "pay_choice"
                        ?
                        <button
                            disabled={
                                purpose === "input" && !inputValue
                            }
                            className={`${purpose === "winner" ? "gradient-orange-button" : "gradient-red-button"} decision-btn`}
                            onClick={() => {

                                if(purpose === "winner") {
                                    if(appealToClose.actualAmount) {
                                        setModalMessage(`Заявка будет закрыта и рассчитана с суммой ${appealToClose.amount} р. в пользу клиенте, вы уверены?`)
                                        action2()
                                        setWinner("merchant")
                                    } else {
                                        action("merchant")
                                    }


                                } else {
                                    action()
                                }
                                setActive("progress")
                                setTimeout(() => {
                                    setActive("no")
                                    // setTimerSeconds(seconds ?? 10)
                                    // setTimerRelease(false)
                                }, 300)
                            }}>{buttonText1 ? buttonText1 : "Да"}
                        </button>
                        :
                        null
                    }
                    {purpose === "no_pay_choice"
                        ?
                        <div className={"modal-no-pay-choice-btn-container"}>

                            <button
                                className={"gradient-red-button"}
                                onClick={() => {

                                    action()
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                        // setTimerSeconds(seconds ?? 10)
                                        // setTimerRelease(false)
                                    }, 300)
                                }}
                            >Платёж не найден</button>
                            <button
                                className={"gradient-orange-button"}
                                onClick={() => action2()}
                            >Сумма в заявке отличается от суммы в чеке</button>
                            <button
                                className={"gradient-dark-button"}
                                onClick={() => {
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                        // setTimerSeconds(seconds ?? 10)
                                        // setTimerRelease(false)
                                    }, 300)
                                    action3()
                                }}
                            >Скам (фальсификация чека)</button>

                        </div>
                        :
                        null
                    }

                    <button

                        className={`${purpose === "winner" ? "gradient-orange-button" : "gradient-dark-button"} decision-btn`}
                        onClick={() => {

                            if(purpose === "winner") {
                                if(appealToClose.actualAmount) {
                                    setModalMessage(`Заявка будет закрыта и рассчитана с суммой ${appealToClose.actualAmount} р. в пользу трейдера, вы уверены?`)
                                    action2()
                                    setWinner("trader")
                                } else {
                                    action("trader")
                                }


                            }

                            setActive("progress")

                            if(purpose !== "pay_choice") {
                                setTimeout(() => {
                                    setActive("no")
                                    // setTimerSeconds(seconds ?? 10)
                                    // setTimerRelease(false)
                                }, 300)
                            } else {
                                action2()
                            }



                        }}>{buttonText2 ?? "Отмена"}
                    </button>
                </div>

            </div>


        </div>
    );
};

export default ModalDialog;