import React, {useEffect, useState} from 'react';
import "../css/personal-data.css";
import {QRCodeSVG} from 'qrcode.react';
import $api from "../http"


const PersonalData = ({store}) => {




    async function testLaunch() {
        const response = await $api.get('/test_launch')
        console.log(response.data)
    }

    async function resetAllValues() {
        const response = await $api.get('/total_reset')
        console.log(response.data)
    }

    const [securedData, setSecuredData] = useState("")
    const [mainWallet, setMainWallet] = useState("")

    const getSecuredData = async () => {
        const response = await $api.get(`/get_secured_data?userId=${store.user.id}`)
        console.log(response.data)
        setSecuredData(response.data.encrypted)
        setMainWallet(response.data.walletAddress)
    }



    useEffect(() => {
        getSecuredData()
    }, []);


    return (
        <div className={"personal-data-wrapper"}>

            <div className={"personal-data-address-container"}>
                <h4 className={"personal-data-title"}>Адрес для пополнения баланса USDT (TRC-20):</h4>
                {mainWallet}
                <div className={"qr-code-wallet-wrapper"}>
                    <QRCodeSVG value={mainWallet}/>
                </div>
            </div>
            <button
                onClick={() => testLaunch()}
            >Тест
            </button>
            <button
                onClick={() => resetAllValues()}
            >Обнуление
            </button>

            <div className={"personal-data-qr-container"}>
                <h4 className={"personal-data-title"}>Код для настройки мобильного приложения:</h4>
                <div className={"qr-code-wallet-wrapper"}>
                    <QRCodeSVG value={securedData}/>
                </div>
            </div>

        </div>
    );
};

export default PersonalData;