import React, {useContext, useEffect, useState} from 'react';
import {v4} from 'uuid'
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import "../css/animations.css"
import BatmanLogoSvg from "../img/batman-logo-thin.svg"

const LoginForm = () => {

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [browserId, setBrowserId] = useState(localStorage.getItem('browserId'))

    const [wrongData, setWrongData] = useState(false)

    const {store} = useContext(Context)


    const tryLogin = async (login, password) => {
        if(await store.login(login, password, browserId) === "FAIL") {
            setWrongData(true)
            setTimeout(() => {setWrongData(false)}, 1500)
        }
    }



    useEffect(() => {

        if(!browserId) {
            localStorage.setItem('browserId', v4())
        }

    }, []);

    useEffect(() => {

        const handleKeyDown = async (e) => {
            if(e.key === "Enter") {
                tryLogin(login, password)
            }
        }

        window.addEventListener('keypress', handleKeyDown)

        return () => {
            window.removeEventListener('keypress', handleKeyDown)
        }

    }, [login, password])

    return (
        <div className={"login-wrapper"}>
            <div className={`login-form ${wrongData ? "shake" : ""}`}>
                <div className={`login-logo ${wrongData ? "red-for-blue" : ""}`}>Gotham Trade</div>
                <img className={"login-page-logo"} src={BatmanLogoSvg}/>

                <input
                    type={"text"}
                    placeholder={"login"}
                    value={login}
                    onChange={(e) => {
                        setLogin(e.target.value)
                    }}
                />
                <input
                    type={"password"}
                    placeholder={"password"}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                />
                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        tryLogin(login, password)
                    }}
                >GO!
                </button>
            </div>

        </div>
    );
};

export default observer(LoginForm);