import React, {useState, useEffect} from 'react';
import TraderCreationForm from "./helpers/TraderCreationForm";
import Select from "react-select";
import MerchantCreationForm from "./helpers/MerchantCreationForm";
import SupportCreationForm from "./helpers/SupportCreationForm";
import TeamleadCreationForm from "./helpers/TeamleadCreationForm";


const UserCreator = ({store}) => {


    const userOptions = [

        {value: "Trader", label: "Трейдер"},
        {value: "Merchant", label: "Мерчант"},
        {value: "Support", label: "Саппорт"},
        {value: "Teamlead", label: "Тимлид"},

    ]

    const [userType, setUserType] = useState()
    const [createError, setCreateError] = useState({show: false, message: ""})

    useEffect(() => {

        if(store.userToEditId || store.merchantToEditId || store.supportToEditId || store.teamleadToEditId) {
            store.setPageTitle("Редактирование пользователя")
        } else {
            store.setPageTitle("Создание пользователя")
        }


    }, []);


    useEffect(() => {
        console.log(store.teamleadToEditId)
    }, []);

    return (

        <div className={"user-creator-wrapper"}>
            {store.userToEditId || store.merchantToEditId || store.supportToEditId || store.teamleadToEditId
                ?
                null
                :
                <Select
                    placeholder={"Тип юзера"}
                    className={"user-creator-selector"}
                    options={userOptions}
                    value={userType ?
                        {
                            value: userType,
                            label: userOptions.find(option => option.value === userType)?.label
                        } : ""
                    }
                    onChange={(selected) => {
                        setCreateError({show: false});
                        setUserType(selected.value)
                    }}

                />

            }
            {userType === "Trader" || store.userToEditId
                ?
                <TraderCreationForm store={store} createError={createError} setCreateError={setCreateError}/>
                :
                null
            }

            {userType === "Merchant" || store.merchantToEditId
                ?
                <MerchantCreationForm store={store} createError={createError} setCreateError={setCreateError}/>
                :
                null
            }

            {userType === "Support" || store.supportToEditId
                ?
                <SupportCreationForm store={store} createError={createError} setCreateError={setCreateError}/>
                :
                null
            }

            {userType === "Teamlead" || store.teamleadToEditId
                ?
                <TeamleadCreationForm store={store} createError={createError} setCreateError={setCreateError}/>
                :
                null
            }


        </div>

    );
};

export default UserCreator;