import React, {useEffect, useRef, useState} from 'react';
import $api from "../http";
import {addDevidersToString, throttle} from "./helpers/helper-functions";
import "../css/sms-viewer.css"
import ProfitTableShort from "./helpers/ProfitTableShort";
import ProfitTableAdmin from "./helpers/ProfitTableAdmin";
import ModalDialog from "./ModalDialog";

const ProfitViewer = ({store}) => {

    const [profits, setProfits] = useState([])
    const [skipCounter, setSkipCounter] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)

    const [processing, setProcessing] = useState(true)

    const startSearchTimer = useRef()


    const [filter, setFilter] = useState({})

    const [profitToPay, setProfitToPay] = useState()

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [activeModalSuccess, setActiveModalSuccess] = useState("no")



    const [traders, setTraders] = useState([])
    const [merchants, setMerchants] = useState([])
    const [teamleads, setTeamleads] = useState([])


    const getTraders = async () => {

        const response = await $api.get(`/get_users_minimal?requesterId=${store.user.id}`)
        console.log(response.data)
        setTraders(response.data)

    }

    const getMerchants = async () => {
        const response = await $api.get(`/get_merchants?requesterId=${store.user.id}`)
        console.log(response.data)
        setMerchants(response.data)

    }

    const getTeamleads = async () => {
        const response = await $api.get(`/get_teamleads`)
        console.log(response.data)
        setTeamleads(response.data)

    }



    const getProfits = async (filter, skip) => {

        setFetching(false)

        const response = await $api.get(`/get_profits?filter=${encodeURIComponent( JSON.stringify(filter) )}&skip=${skip}`)

        console.log(response.data)
        setProcessing(false)

        if(response.data.stopFetching) {
            setStopFetching(true)
            setSkipCounter(0)
        }
        setSkipCounter(prev => prev + response.data.profits.length)
        setProfits(prev => [...prev, ...response.data.profits])


    }


    const payOff = async (profitId, filter) => {

        try {

            const response = await $api.post(`/payoff`, {profitId})


            console.log(response.data)

            if(response.data.paid) {

                console.log("Активируем окошко")

                setModalMessage(`Выплата на ${addDevidersToString(response.data.amount.toFixed(2))} USDT для ${response.data.user.login} погашена успешно`)

                setActiveModalSuccess("progress")
                setTimeout(() => {
                    setActiveModalSuccess("active")
                }, 100)
            }


            setProfits(prev => {
                const newProfitState = prev.map(profit => {
                    if(profit._id === response.data._id && filter.paid === 'false') {
                        console.log("FIRST CASE")
                        return null
                    } else if(profit._id === response.data._id) {
                        console.log("SECOND CASE")
                        return response.data
                    } else {
                        console.log("THIRD CASE")
                        return profit
                    }
                })

                const newStateFiltered = newProfitState.filter(profit => profit !== null)

                return newStateFiltered
            })

        } catch (e) {
            setModalMessage(e.message)

            setActiveModalSuccess("progress")
            setTimeout(() => {
                setActiveModalSuccess("active")
            }, 100)
        }


    }


    useEffect(() => {
        if(store.user?.admin) {
            getMerchants()
            getTraders()
            getTeamleads()
        }

    }, [store.user])


    useEffect(() => {

        store.setPageTitle("Профиты")

        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getProfits(filter, skipCounter)
            }, 1500)
        }


    }, [fetching, skipCounter, stopFetching])


    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ


        const throttledHandler = throttle(scrollHandler, 300);
        document.addEventListener('scroll', throttledHandler);

        return function cleanup() {
            console.log("DO REMOVE");
            document.removeEventListener('scroll', throttledHandler);
        };


    }, [])


    function scrollHandler(e) {

        console.log("SCROLLING")

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)

        }

    }


    return (
        <div className={"profit-viewer-wrapper"}>
            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));

                        setProfits([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <label>По</label>
                <input
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));

                        setProfits([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                {store.user.admin
                    ?
                    <select
                        defaultValue={""}
                        value={filter.status}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                type: e.target.value
                            }))

                            setProfits([])
                            setFilter(prev => ({
                                ...prev,
                                userId: ""
                            }))

                            setSkipCounter(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Площадка</option>
                        <option value={"User"}>Трейдеры</option>
                        <option value={"Merchant"}>Мерчанты</option>
                        <option value={"Teamlead"}>Тимлиды</option>
                    </select>
                    :
                    null
                }
                {store.user.admin && filter.type
                    ?
                    <select
                        value={filter.userId}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                userId: e.target.value
                            }))

                            setProfits([])

                            setSkipCounter(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        {filter.type === "User"
                            ?
                            traders.map(trader => <option id={trader.id} value={trader.id}>{trader.login}</option>)
                            :
                            filter.type === "Merchant"
                                ?
                                merchants.map(merchant => <option id={merchant._id} value={merchant._id}>{merchant.login}</option>)
                                :
                                teamleads.map(teamlead => <option id={teamlead._id} value={teamlead._id}>{teamlead.login}</option>)

                        }
                    </select>
                    :
                    null
                }
                {store.user.admin && filter.type
                    ?
                    <select
                        value={filter.paid}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                paid: e.target.value
                            }))

                            setProfits([])

                            setSkipCounter(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        <option value={true}>Выплаченные</option>
                        <option value={false}>Не выплаченные</option>

                    </select>
                    :
                    null
                }
                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        setProcessing(true)
                        setFilter({status: "", startDate: "", stopDate: ""})
                        setProfits([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >Сброс
                </button>

            </div>
            {profits.length
                ?
                store.user.admin
                    ?
                    <ProfitTableAdmin
                        profits={profits}
                        filter={filter}
                        payOff={payOff}
                        setProfitToPay={setProfitToPay}
                        setModalMessage={setModalMessage}
                        setActiveModal={setActiveModal}
                    />
                    :
                    <ProfitTableShort profits={profits}/>
                :
                processing
                    ?
                    <img src={require('../img/loading.gif')} height={"40px"}
                         className={"margin-left-20"}/>
                    :
                    <h2>Нет ничего соответствующего вашему запросу</h2>
            }

            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                action={() => {

                    payOff(profitToPay, filter)

                    setActiveModal("progress")
                    setTimeout(() => {
                        setActiveModal("no")
                    }, 100)
                }}
                message={modalMessage}
                purpose={"choice"}
            />


            <ModalDialog
                active={activeModalSuccess}
                setActive={setActiveModalSuccess}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />

        </div>
    );
};

export default ProfitViewer;