import React, {useEffect, useRef, useState} from 'react';
import $api from "../http";
import SupportAppealTable from "./helpers/SupportAppealTable";
import {throttle} from "./helpers/helper-functions";
import TraderAppealTable from "./helpers/TraderAppealTable";


const AppealViewer = ({store, appealSupportUpdate, appealTraderUpdate, traders, merchants}) => {


    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ
        document.addEventListener('scroll', throttle(scrollHandler, 300))

        //при размонтировании элемента эта функция так же отключает слушатель события
        return function() {
            console.log("DO REMOVE")
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [])

    function scrollHandler(e) {

        console.log("SCROLLING")

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)

        }


    }


    const [appeals, setAppeals] = useState([])
    const [processing, setProcessing] = useState(true)

    const startSearchTimer = useRef()


    const [skipCounter, setSkipCounter] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)
    const [filter, setFilter] = useState({status: "pending", onlyMine: store.user.id})


    const getAppeals = async (filter, skip) => {

        setFetching(false)


        setProcessing(true)
        const response = await $api.get(`/appeals?filter=${encodeURIComponent(JSON.stringify(filter))}&skip=${skip}`)

        if(response.data?.stopFetching) {
            setStopFetching(true)
            setSkipCounter(0)
        }
        setSkipCounter(prev => prev + response.data.appeals.length)
        setAppeals(response.data?.appeals)
        setProcessing(false)
    }




    useEffect(() => {


        store.setPageTitle("Апелляции")


        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getAppeals(filter, skipCounter)
            }, 1500)
        }


    }, [filter, skipCounter, stopFetching])


    // const [traders, setTraders] = useState([])
    // const [merchants, setMerchants] = useState([])
    //
    //
    // const getTraders = async () => {
    //
    //     const response = await $api.get(`/get_users?requesterId=${store.user.id}`)
    //     console.log(response.data)
    //     setTraders(response.data)
    //
    // }
    //
    // const getMerchants = async () => {
    //     const response = await $api.get(`/get_merchants?requesterId=${store.user.id}`)
    //     console.log(response.data)
    //     setMerchants(response.data)
    //
    // }
    //
    //
    // useEffect(() => {
    //     if(store.user?.roles.includes("support")) {
    //         getMerchants()
    //         getTraders()
    //     }
    //
    // }, [store])


    const closeAppeal = async (appealId, winner) => {
        console.log(appealId, winner)
        const response = await $api.get(`/close_appeal?appealId=${appealId}&winner=${winner}`)
        console.log(response.data)


        setAppeals(prev => {
            const newAppealState = prev.filter(appeal => appeal._id !== response.data._id.toString())
            return newAppealState

        })

        // setAppeals(prev => {
        //     const newAppealState = prev.map(appeal => {
        //         if(appeal._id !== response.data._id) {
        //             return appeal
        //         } else {
        //             return response.data
        //         }
        //     })
        //
        //     return newAppealState
        // })

    }


    useEffect(() => {

        if(appealSupportUpdate && Object.keys(appealSupportUpdate)) {


            if(
                appealSupportUpdate.event === "appeal_support_new"
                && filter.status === "pending"
                && (appealSupportUpdate.data.supports.includes(store.user.id) || !filter.onlyMine)
                && (appealSupportUpdate.data.merchant._id === filter.merchant || !filter.merchant)
            ) {
                setAppeals(prev => [appealSupportUpdate.data, ...prev])
            }

            if(appealSupportUpdate.event === "appeal_support_close" && filter.status === "pending") {
                setAppeals(prev => {
                    const newAppealState = prev.filter(appeal => appeal._id !== appealSupportUpdate.data._id.toString())
                    return newAppealState

                })
            } else if(
                appealSupportUpdate.event === "appeal_support_close"
                && filter.status === "closed"
                && appealSupportUpdate.data.supports.includes(store.user.id)
                && filter.onlyMine
                && (appealSupportUpdate.data.merchant._id === filter.merchant || !filter.merchant)
            ) {


                setAppeals(prev =>
                    [appealSupportUpdate.data, ...prev].sort((a, b) => b.number - a.number)
                );

            } else if(appealSupportUpdate.event === "appeal_support_refresh") {
                setAppeals(prev => {
                    const newAppealState = prev.map(appeal => {
                        if(appeal._id !== appealSupportUpdate.data._id.toString()) {
                            return appeal
                        } else {
                            return appealSupportUpdate.data
                        }
                    })

                    return newAppealState

                })
            }


        }


    }, [appealSupportUpdate]);


    useEffect(() => {


        console.log("appealTraderUpdate TRIGGERED")
        console.log(filter)

        if(appealTraderUpdate && Object.keys(appealTraderUpdate)) {

            if(
                appealTraderUpdate.event === "appeal_trader_new"
                && filter.status === "pending"
                // && (appealSupportUpdate.data.supports.includes(store.user.id) || !filter.onlyMine)
                // && (appealSupportUpdate.data.merchant._id === filter.merchant || !filter.merchant)
            ) {
                setAppeals(prev => [appealTraderUpdate.data, ...prev])

            } else if(appealTraderUpdate.event === "appeal_trader_refresh") {

                console.log("ДОШЛИ ДО ЕЛЗЕ ИФ")

                setAppeals(prev => {
                    const newAppealState = prev.map(appeal => {

                        if(appeal._id !== appealTraderUpdate.data._id.toString()) {
                            console.log("ИФ ВНУТРИ")
                            return appeal
                        } else {
                            console.log("ЕЛЗЕ ВНУТРИ")
                            return appealTraderUpdate.data
                        }
                    })

                    return newAppealState

                })
            }


            if(appealTraderUpdate.event === "appeal_trader_close" && filter.status === "pending") {
                setAppeals(prev => {
                    const newAppealState = prev.filter(appeal => appeal._id !== appealTraderUpdate.data._id.toString())
                    return newAppealState

                })
            } else if(
                appealTraderUpdate.event === "appeal_trader_close"
                && filter.status === "closed"

            ) {


                setAppeals(prev =>
                    [appealTraderUpdate.data, ...prev].sort((a, b) => b.number - a.number)
                );

            }

        }

    }, [appealTraderUpdate])



    return (
        <div className={"appeal-viewer-wrapper"}>
            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {

                        setAppeals([])
                        setProcessing(true)
                        setSkipCounter(0);

                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));


                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);

                    }}
                />
                <label>По</label>
                <input
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {

                        setAppeals([])
                        setProcessing(true)
                        setSkipCounter(0);

                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));


                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <select

                    value={filter?.status}
                    onChange={(e) => {

                        setAppeals([])
                        setProcessing(true)
                        setSkipCounter(0);

                        setFilter(prev => ({
                            ...prev,
                            status: e.target.value
                        }))


                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >
                    <option value={"pending"}>Открытые</option>
                    <option value={"closed"}>Закрытые</option>

                </select>
                {store.user.roles.includes("support")
                    ?
                    <select

                        value={filter?.onlyMine}
                        onChange={(e) => {

                            setAppeals([])
                            setProcessing(true)
                            setSkipCounter(0);

                            setFilter(prev => ({
                                ...prev,
                                onlyMine: e.target.value
                            }))


                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={`${store.user.id}`}>Только мои</option>
                        <option value={""}>Все</option>

                    </select>
                    :
                    null
                }

                {store.user.admin || store.user.roles.includes("support")
                    ?
                    <select
                        value={filter.merchant}
                        onChange={(e) => {

                            setAppeals([])
                            setProcessing(true)
                            setSkipCounter(0);

                            setFilter(prev => ({
                                ...prev,
                                merchant: e.target.value
                            }))

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все мерчанты</option>
                        {merchants.map(merchant => <option id={merchant._id} value={merchant._id}>{merchant.login}</option>)}
                    </select>
                    :
                    null
                }
                {store.user.admin || store.user.roles.includes("support")
                    ?
                    <select
                        value={filter.trader}
                        onChange={(e) => {

                            setAppeals([])
                            setProcessing(true)
                            setSkipCounter(0);

                            setFilter(prev => ({
                                ...prev,
                                trader: e.target.value
                            }))

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все трейдеры</option>
                        {traders.map(trader => <option id={trader.id} value={trader.id}>{trader.login}</option>)}
                    </select>
                    :
                    null
                }
                <button
                    className={"gradient-dark-button"}
                    onClick={() => {

                        setAppeals([])
                        setProcessing(true)
                        setSkipCounter(0);

                        setFilter({status: "pending", onlyMine: store.user.id, startDate: "", stopDate: ""})


                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >Сброс
                </button>


            </div>

            {store.user.roles.includes("support")
                ?
                <SupportAppealTable appeals={appeals} processing={processing} closeAppeal={closeAppeal}/>
                :
                <TraderAppealTable appeals={appeals} processing={processing} closeAppeal={closeAppeal}/>
            }


        </div>
    );
};

export default AppealViewer;