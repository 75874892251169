import React, {useEffect, useState} from 'react';
import {generatePassword} from "./helper-functions";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import $api from "../../http";
import "../../css/user-creator.css"

const SupportCreationForm = ({store, createError, setCreateError}) => {

    const navigate = useNavigate()

        
    const [showOk, setShowOk] = useState(false)
    
    const [supportData, setSupportData] = useState({})


    const getSupportToEdit = async (supportId) => {
        const response = await $api.get(`/get_support_by_id?supportId=${supportId}`)
        console.log(response.data)
        setSupportData(prev => {
            setCreateError({show: false})
            return {
                ...prev,
                id: response.data.id,
                login: response.data.login,
                tgUserName: response.data.tgUserName
            }
        })
    }


    useEffect(() => {

        if(store.supportToEditId) {
            getSupportToEdit(store.supportToEditId)
        }


        return () => {
            store.setSupportToEditId("")
        }


    }, []);

    const createSupport = async (supportData) => {

        console.log(supportData)


        // Список обязательных полей
        const requiredFields = [
            'login',
            'tgUserName'

        ];

        if(!store.supportToEditId) {
            requiredFields.push("password")
        }


        // Проверка на заполнение всех полей
        const allFieldsFilled = requiredFields.every(field => {
            console.log(field, supportData[field] !== undefined, supportData[field] !== null, supportData[field] !== '')
            return supportData[field] !== undefined && supportData[field] !== null && supportData[field] !== '';
        });

        if (allFieldsFilled) {
            // Если все поля заполнены

            console.log(supportData)
            console.log("Всё норм создаем юзера")
            try {
                const response = await $api.post('/create_support', {supportData: JSON.stringify(supportData)})
                if(response.data === "OK") {
                    setShowOk(true)
                    setSupportData({
                        'login': "",
                        'password': "",
                        'tgUserName': "",

                    })
                    setTimeout(() => {
                        setShowOk(false)
                        store.setSupportToEditId("")
                        navigate('/support_viewer')
                    }, store.supportToEditId ? 2000 : 5000)
                } else {
                    setCreateError({show: true, message: response.data.message})
                }

            } catch (e) {
                console.log(e)
                setCreateError({show: true, message: "Ошибка создания юзера"})
            }



        } else {
            // Если какие-то поля не заполнены, выполняем другое действие
            setCreateError({show: true, message: "Заполнены не все поля"})
        }
    };


    function validateInputLogin(input) {

        const validPattern = /^[A-Za-z0-9]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setSupportData(prev => {
                setCreateError({show: false})
                return {
                    ...prev,
                    login: input
                }
            })
        }
    }

    function validateTgUserName(input) {

        const validPattern = /^[A-Za-z0-9@_]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setSupportData(prev => {
                setCreateError({show: false})
                return {
                    ...prev,
                    tgUserName: input
                }
            })
        }
    }



    return (
        <div className={"support-creator-wrapper"}>
            <h1 className={"component-title"}>{store.supportToEditId ? "Редактируем" : "Создаём"} саппорта</h1>

            <input
                className={"user-creator-input"}
                type={"text"}
                pattern="[A-Za-z0-9]+"
                placeholder={"Имя (login)"}
                value={supportData.login}
                onChange={(e) => {
                    validateInputLogin(e.target.value)

                }}
            />

            <div className={"user-creator-password-input-n-generator-container"}>
                <input
                    readOnly={true}
                    className={"user-creator-input password-input"}
                    type={"text"}
                    placeholder={"password"}
                    value={supportData.password}

                />
                <button
                    className={"gradient-dark-button pass-gen-btn"}
                    onClick={() => {
                        const freshPass = generatePassword()
                        setSupportData(prev => {
                            setCreateError({show: false})
                            return {
                                ...prev,
                                password: freshPass
                            }
                        })
                    }}
                >{store.supportToEditId ? "New Password" : "Generate"}
                </button>
            </div>

            <label
                className={"password-warning red-for-blue"}
            >{supportData.password
                ?
                `Скопируйте пароль, после ${store.supportToEditId ? 'сохранения' : 'создания'} трейдера его больше нельзя будет увидеть`
                :
                null
            }
            </label>

            <input
                className={"user-creator-input"}
                type={"text"}
                pattern="[A-Za-z0-9@_]+"
                placeholder={"Ник в телеграме"}
                value={supportData.tgUserName}
                onChange={(e) => {
                    validateTgUserName(e.target.value)

                }}
            />


            <div className={"create-user-btn-container"}>
                {createError.show
                    ?
                    <h3 className={"create-user-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-user-success-message"}>Пользователь {store.supportToEditId ? "сохранён" : "добавлен"} успешно!</h3>
                    :
                    null
                }
                <button
                    className={"gradient-dark-button create-user-btn"}
                    onClick={() => createSupport(supportData)}
                >{store.supportToEditId ? "Сохранить" : "Создать"}
                </button>
            </div>


        </div>
    );
};

export default SupportCreationForm;