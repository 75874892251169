import React, {useContext, useEffect, useState} from 'react';
import BurgerButton from '../img/burger-menu.svg'
import CloseMenuCross from "../img/close-menu-cross.svg"
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const Header = ({isMenuOpen, setIsMenuOpen, store}) => {



    return (
        <header className="header">

            <div className="header-left">
                <img
                    className={`burger-button ${isMenuOpen ? 'open' : ''}`}
                    src={isMenuOpen ? CloseMenuCross : BurgerButton}
                    width={"35px"}

                    alt={"Burger button"}
                    onClick={() => setIsMenuOpen(prev => !prev)}
                />
                <div className="title">Gotham Trade</div>

            </div>

            <div className={"header-center"}>
                <h4 className={"header-page-title"}>{store.pageTitle}</h4>
            </div>

            <div className="header-right">
                <h1 className={"header-username"}>{store.user.login}</h1>
                <button
                    className={"gradient-dark-button logout-btn"}
                    onClick={() => store.logout(localStorage.getItem('browserId'))}
                >Exit
                </button>
            </div>
        </header>

);
};

export default observer(Header);