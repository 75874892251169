import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/user-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";

const MerchantViewer = ({store}) => {


    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState({status: false, index: null})

    const [merchants, setMerchants] = useState([])

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const getMerchants = async () => {
        setLoading(true)
        const response = await $api.get('/get_merchants')
        console.log(response.data)
        setMerchants(response.data)
        setLoading(false)
    }


    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (apiKey, index) => {
        navigator.clipboard.writeText(apiKey);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };

    // const changeTraderStatus = async (userId, index) => {
    //
    //     setProcessing({status: true, index})
    //
    //     try {
    //         console.log(userId, index)
    //         const response = await $api.get(`/change_trader_status?userId=${userId}`)
    //         console.log(response.data)
    //
    //         // Получаем обновленный объект карты из ответа
    //         const updatedUser = response.data;
    //
    //         // Обновляем состояние карт
    //         setMerchants(prevUsers =>
    //             prevUsers.map(user =>
    //                 user.id === updatedUser.id
    //                     ? { ...user, active: updatedUser.active } // Копируем все поля и меняем только поле active
    //                     : user
    //             )
    //         );
    //
    //     } catch (e) {
    //         console.log(e)
    //     }
    //
    //
    //
    //
    //     setProcessing({status: false, index})
    //
    // }


    useEffect(() => {
        store.setPageTitle("Мерчанты")
        getMerchants()
    }, []);

    return (
        <div className={"user-viewer-wrapper"}>
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>login</th>
                    <th>Cтавка (%)</th>
                    {/*<th>API-key</th>*/}
                    <th>Изменение</th>

                </tr>
                </thead>
                <tbody>
                {merchants.map((merchant, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr key={merchant.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{merchant.login}</td>
                            <td>{merchant.stake}</td>
                            {/*<td>*/}
                            {/*    <button*/}
                            {/*        className="copy-button"*/}
                            {/*        onClick={() => handleCopy(merchant.apiKey, index)}*/}
                            {/*    >*/}
                            {/*        {copiedIndex === index ? 'Copied' : 'Copy API-key'}*/}
                            {/*    </button>*/}
                            {/*</td>*/}
                            <td>
                                <button
                                    className={"gradient-orange-button"}
                                    onClick={() => {
                                        store.setMerchantToEditId(merchant._id)
                                        navigate('/create_user')

                                    }}
                                >Редактировать
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {loading
                ?
                <img src={require('../img/loading.gif')} height={"35px"} className={"margin-left-20"}/>
                :
                null
            }
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />
        </div>
    );
};

export default MerchantViewer;