import React, {useEffect, useState} from 'react';
import "../css/settings.css"
import "../css/helpers.css"
import $api from "../http";
import BanksTable from "./helpers/BanksTable";


const Settings = ({store}) => {


    const [usdtPrice, setUsdtPrice] = useState(0)
    const [usdtFreshPrice, setUsdtFreshPrice] = useState()

    const [stopCrane, setStopCrane] = useState(false)

    const [newBank, setNewBank] = useState({})
    const [banks, setBanks] = useState([])


    const [errorMessage, setErrorMessage] = useState({show: false, target: "", message: ""})


    const getBanks = async () => {
        const response = await $api.get('/get_banks')

        setBanks(response.data)

    }


    const getUsdtPrice = async () => {
        const response = await $api.get('/get_ustd_price')

        console.log(response.data)

        setUsdtPrice(response.data)
    }


    const getStopCrane = async () => {

        const response = await $api.get('/get_stop_crane')

        console.log(response.data)

        setStopCrane(response.data)

    }

    const refreshStopCrane = async (stopCraneState) => {

        const response = await $api.post('/set_stop_crane', {stopCraneState})

        console.log(response.data)

        setStopCrane(response.data)

    }




    const validateFreshUsdtPrice = async (price) => {


        if(price < 150 && price > 85) {
            refreshUsdtPrice(price)
        } else {
            setErrorMessage({show: true, target: "usdt", message: "Давайте нормальный курс установим"})
        }

    }


    const refreshUsdtPrice = async (price) => {

        try {
            const response = await $api.post('/set_usdt_price', {price})
            setUsdtPrice(response.data)
            setUsdtFreshPrice("")
        } catch (e) {
            setErrorMessage({show: true, message: e.message})
        }


    }


    function validateBankCode(input) {

        const validPattern = /^[A-Za-z-]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setNewBank(prev => {
                setErrorMessage({show: false})
                return {
                    ...prev,
                    code: input
                }
            })
        }

    }



    const addBank = async (bank) => {

        if(bank.name && bank.code) {

            try {
                const response = await $api.post("/add_bank", {...bank})

                console.log(response.data)

                if(response.data.status === "OK") {

                    setNewBank({name: "", code: ""})

                    setErrorMessage({show: true, target: "bank", color: "green", message: "Банк добавлен"})

                    setTimeout(() => {
                        setErrorMessage({show: false})
                    }, 2000)

                    setBanks(prev => [response.data.bankInfo, ...prev])
                } else {
                    setErrorMessage({show: true, target: "bank", message: response.data.message})
                }
            } catch (e) {
                setErrorMessage({show: true, target: "bank", message: e.message})
            }




        } else {
            setErrorMessage({show: true, target: "bank", message: "Не все поля заполнены"})
        }

    }



    useEffect(() => {
        store.setPageTitle("Настройки площадки")
        getUsdtPrice()
        getStopCrane()
        getBanks()


    }, []);



    return (
        <div className={"settings-wrapper"}>
            <div className={"settings-usdt-wrapper"}>
                <div className={"settings-usdt-header"}>
                    <h5 className={"settings-label"}>Настройки USDT</h5>
                    <div className={"settings-error-message-wrapper"}>
                        {errorMessage.show && errorMessage.target === "usdt"
                            ?
                            <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>
                            :
                            null
                        }
                    </div>
                </div>

                <div className={"settings-usdt-setter"}>
                    <div className={"settings-usdt-price-monitor"}>
                        Курс площадки
                        <h1 className={"settings-usdt-value"}>{usdtPrice} ₽</h1>
                    </div>
                    <input
                        placeholder={"Новый курс"}
                        value={usdtFreshPrice}
                        onChange={(e) => {
                            setErrorMessage({show: false, target: "", message: ""})
                            setUsdtFreshPrice(e.target.value)
                        }
                        }
                        type={"number"}
                    />
                    <button
                        onClick={() => {
                            validateFreshUsdtPrice(usdtFreshPrice)
                        }}
                        className={"gradient-orange-button"}
                    >Установить
                    </button>
                </div>



            </div>

            <div className={"settings-stop-crane-wrapper"}>
                <div className={"settings-stop-crane-header"}>
                    <h5 className={"settings-label"}>СТОП-КРАН</h5>
                    <div className={"settings-error-message-wrapper"}>
                        {errorMessage.show && errorMessage.target === "stop"
                            ?
                            <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>
                            :
                            null
                        }
                    </div>
                </div>

                <div className={"settings-stop-crane-setter"}>

                    <div className="circle-container">
                        <div className={`${stopCrane ? "green-circle" : "red-circle"}`}></div>
                    </div>
                    <div className={"settings-stop-crane-indicator-container"}>
                        <h3 className={`stop-crane-indicator ${stopCrane? "red-text" : "green-text"}`}>
                            ПЛОЩАДКА {`${stopCrane ? "ОСТАНОВЛЕНА" : "РАБОТАЕТ" }`}
                        </h3>
                        <button
                            className={`${stopCrane ? "gradient-green-button" : "gradient-red-button"} stop-button`}
                            onClick={() => refreshStopCrane(!stopCrane)}
                        >{stopCrane ? "ПУСК" : "СТОП"}</button>
                    </div>


                </div>

            </div>

            <div className={"settings-banks-wrapper"}>
            <div className={"settings-banks-header"}>
                    <h5 className={"settings-label"}>Настройки банков</h5>
                    <div className={"settings-error-message-wrapper"}>
                        {errorMessage.show && errorMessage.target === "bank"
                            ?
                            <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>
                            :
                            null
                        }
                    </div>
                </div>

                <div className={"settings-bank-adder"}>
                    <input
                        type={"text"}
                        placeholder={"Название"}
                        value={newBank.name}
                        onChange={(e) => {
                            setNewBank(prev => {
                                setErrorMessage({show: false})
                                return {
                                    ...prev,
                                    name: e.target.value
                                }
                            })
                        }}
                    />
                    <input
                        type={"text"}
                        placeholder={"код"}
                        value={newBank.code}
                        onChange={(e) => validateBankCode(e.target.value)}
                    />
                    <button
                        className={"gradient-orange-button"}
                        onClick={() => addBank(newBank)}
                    >Добавить</button>
                </div>

                <BanksTable banks={banks}/>

            </div>

        </div>
    );
};

export default Settings;