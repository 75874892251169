import React, {useState} from 'react';
import $api from "../../http";
import ModalDialog from "../ModalDialog";

const TraderOrderTable = ({orders, processing}) => {

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [orderToComplete, setOrderToComplete] = useState()

    const confirmOrder = async (orderId) => {
        console.log(orderId)
        const response = await $api.get(`/confirm_order?orderId=${orderId}`)
        console.log(response.data)
    }



    return (

                orders.length
                    ?
                    <table className="transaction-table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Дата</th>
                            <th>Сумма (руб)</th>
                            <th>Сумма (usdt)</th>
                            <th>Карта</th>
                            <th>Статус</th>
                            <th className={"fixed-table-width"}>Профит (usdt)</th>
                            <th className={"fixed-table-width"}>Управление</th>

                        </tr>
                        </thead>
                        <tbody>
                        {orders?.map((order, index) => {
                            // const formattedDate = new Date(card.block_timestamp)
                            //     .toLocaleString('ru-RU', options);

                            return (
                                <tr
                                    key={order.id}
                                    className={
                                        order.status === "failed"
                                            ?
                                            "red-row"
                                            :
                                            order.status === "opened"
                                                ?
                                                "green-row"
                                                :
                                                index % 2 === 0
                                                    ?
                                                    'even-row'
                                                    :
                                                    'odd-row'
                                    }
                                >
                                    <td>{order.number}</td>
                                    <td>{new Date(order.openDate).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</td>
                                    <td>{order.amount}</td>
                                    <td>{order.usdtAmount.toFixed(2)}</td>
                                    <td>{"*" + order.card?.number?.slice(-4)}</td>
                                    <td>{order.status}</td>
                                    <td>{order.traderProfit.toFixed(2)}</td>
                                    <td>
                                        {order.status === "opened"
                                            ?
                                            <button
                                                className={"gradient-orange-button"}
                                                onClick={() => {
                                                    setActiveModal("progress")
                                                    setTimeout(() => {
                                                        setActiveModal("active")
                                                    }, 100)
                                                    setOrderToComplete(order)
                                                }}
                                            >
                                                Подтвердить
                                            </button>
                                            :
                                            null
                                        }
                                    </td>

                                </tr>
                            );
                        })}
                        </tbody>
                        <ModalDialog
                            active={activeModal}
                            setActive={setActiveModal}
                            action={() => {
                                confirmOrder(orderToComplete?.id)
                            }}
                            message={`Вы подтверждаете поступление денег по заказу #${orderToComplete?.number}?`}
                            purpose={"choice"}
                        />
                    </table>
                    :
                    processing
                        ?
                        <img src={require('../../img/loading.gif')} height={"40px"}
                             className={"margin-left-20"}/>
                        :
                        <h2>Нет ничего соответствующего вашему запросу</h2>






    );
};

export default TraderOrderTable;