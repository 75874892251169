import React from 'react';

const EmptyMessage = ({message}) => {
    return (
        <div className={"empty-message-wrapper"}>
            <div className={"empty-message-frame"}>
                <div className={"empty-message-white-shield"}>
                    <p className={"empty-message-content"}>{message}</p>
                </div>
            </div>
        </div>
    );
};

export default EmptyMessage;