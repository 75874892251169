import React, {useEffect, useRef, useState} from 'react';
import {API_IP} from "../../http";


const WsLogger = ({logString}) => {


    const [logs, setLogs] = useState([])


    function generateUserId(length = 15) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Допустимые символы для ключа
        let userId = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            userId += characters[randomIndex];
        }

        return userId;
    }


    useEffect(() => {

        if(logString) {
            setLogs(prev => [logString, ...prev])
        }

    }, [logString]);


    const notifySocket = useRef()



    async function connectToWebSocket() {

        notifySocket.current = new WebSocket(`wss://${API_IP}:3030`,)

        notifySocket.current.onopen = async () => {

            const message = {
                event: 'connection-logger',
                user: 'logger_' + generateUserId(),

            }

            console.log(message)

            //await console.log({...store.user}.id)
            await notifySocket.current.send(JSON.stringify(message))


        }

        notifySocket.current.onmessage = (event) => {
            const message = JSON.parse(event.data)
            console.log(message)


            if(message.event === "wss_log") {

                setLogs(prev => [message.data, ...prev])

            }
        }




        notifySocket.current.onclose = () => {

            setTimeout(connectToWebSocket, 3000)
        }

        notifySocket.current.onerror = () => {
            console.log("Ошибка сокета")
        }
    }

    useEffect(() => {

            connectToWebSocket()

    }, []);


    return (
        <div className={"ws-logs"}>
            <h1>Логи Websockets</h1>
            {logs.map(str => <p>{str}</p>)}
        </div>
    );
};

export default WsLogger;