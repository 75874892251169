import React from 'react';
import '../css/switch.css'; // Импортируем стили

const Switch = ({ isChecked, onChange }) => {
    return (
        <label className="switch">
            <input type="checkbox" checked={isChecked} onChange={onChange} />
            <span className="slider"></span>
        </label>
    );
};

export default Switch;