import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Пример данных: количество заявок по часам
const data_old = [
    { hour: '00:00', requests: 20 },
    { hour: '01:00', requests: 45 },
    { hour: '02:00', requests: 30 },
    { hour: '03:00', requests: 60 },
    { hour: '04:00', requests: 40 },
    { hour: '05:00', requests: 80 },
    { hour: '06:00', requests: 70 },
    { hour: '07:00', requests: 55 },
    { hour: '08:00', requests: 90 },
    { hour: '09:00', requests: 100 },
    { hour: '10:00', requests: 85 },
    { hour: '11:00', requests: 120 },
    { hour: '12:00', requests: 95 },
    { hour: '13:00', requests: 110 },
    { hour: '14:00', requests: 85 },
    { hour: '15:00', requests: 75 },
    { hour: '16:00', requests: 90 },
    { hour: '17:00', requests: 100 },
    { hour: '18:00', requests: 130 },
    { hour: '19:00', requests: 110 },
    { hour: '20:00', requests: 80 },
    { hour: '21:00', requests: 70 },
    { hour: '22:00', requests: 60 },
    { hour: '23:00', requests: 50 },
];

const Chart = ({data}) => (
    <div className={"chart-wrapper"}>
        <AreaChart width={800} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="hour" tickFormatter={(tick) => tick}/>
            <YAxis/>
            {/*<Tooltip/>*/}
            {/*<Legend/>*/}
            <Area type="monotone" dataKey="requests" stroke="#8884d8" fill="#8884d8"/>
        </AreaChart>
        <div style={{fontFamily: "Roboto", textAlign: 'center', marginTop: '10px', fontSize: '16px', fontWeight: 'bold'}}>
            Заявки в час (МСК)
        </div>
    </div>

);

export default Chart;
