import React, {useState} from 'react';
import $api, {API_URL} from "../../http";
import ModalDialog from "../ModalDialog";
import ModalImgViewer from "./ModalImgViewer";

const SupportAppealTable = ({appeals, processing, closeAppeal}) => {

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")
    const [activeActionModal, setActiveActionModal] = useState("no")
    const [activeActualAmountModal, setActiveActualAmountModal] = useState("no")

    const [winner, setWinner] = useState()

    const [activeImgModal, setActiveImgModal] = useState("no")
    const [imgSrc, setImgSrc] = useState("")

    const [appealToClose, setAppealToClose] = useState()





    return (

        appeals.length
            ?
            <div className={"transaction-table-wrapper"}>
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>Дата</th>
                    <th>Номер</th>
                    <th>Заявка</th>
                    <th>Мерчант</th>
                    <th>Трейдер</th>
                    <th>Сумма (руб)</th>
                    <th>Решение</th>
                    <th>Инфо</th>
                    <th>От Мерчанта</th>
                    <th>Актуальная сумма</th>
                    <th>Чек</th>

                    <th className={"fixed-table-width"}>Управление</th>

                </tr>
                </thead>
                <tbody>
                {appeals.length
                    ?
                    appeals?.map((appeal, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr
                            key={appeal._id}
                            className={
                                index % 2 === 0
                                    ?
                                    'even-row'
                                    :
                                    'odd-row'
                            }
                        >
                            <td>{new Date(appeal.created_at).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</td>
                            <td>{appeal.number}</td>
                            <td>{appeal.order?.number}</td>
                            <td>{appeal.merchant?.login}</td>
                            <td>{appeal.trader?.login}</td>
                            <td>{appeal.amount}</td>
                            <td>{appeal.resolution}</td>
                            <td>{appeal.traderDescription}</td>
                            <td>{appeal.merchantDescription}</td>
                            <td>{appeal.actualAmount}</td>
                            <td>
                                {appeal.receipt
                                    ?
                                    <button
                                        className={"gradient-orange-button"}
                                        onClick={() => {

                                            console.log(appeal.receipt)
                                            if (appeal.receipt?.toLowerCase().endsWith(".pdf")) {
                                                // Формируем полный URL до файла


                                                const fileUrl = `${API_URL}/${appeal.receipt}`;
                                                window.open(fileUrl, "_blank");
                                                return;
                                            }

                                            setActiveImgModal("progress")
                                            setTimeout(() => {
                                                setActiveImgModal("active")
                                            }, 100)
                                            setImgSrc(appeal.receipt)
                                        }}
                                    >
                                        Показать
                                    </button>
                                    :
                                    null
                                }
                            </td>
                            <td>
                                {appeal.status !== "closed" && appeal.traderDescription
                                    ?
                                    <button
                                        className={"gradient-orange-button"}
                                        onClick={() => {
                                            setActiveModal("progress")
                                            setTimeout(() => {
                                                setActiveModal("active")
                                            }, 100)
                                            setAppealToClose(appeal)
                                        }}
                                    >
                                        Закрыть
                                    </button>
                                    :
                                    null
                                }
                            </td>

                        </tr>
                    );
                })
                :
                null
                }
                </tbody>


            </table>
                <ModalDialog
                    active={activeModal}
                    setActive={setActiveModal}
                    action={() => {
                        setActiveActionModal("progress")
                        setTimeout(() => {
                            setActiveActionModal("active")
                        }, 100)
                    }}
                    message={`Вы подтверждаете закрытие апелляции #${appealToClose?.number}?`}
                    purpose={"choice"}
                />
                <ModalDialog
                    active={activeActionModal}
                    setActive={setActiveActionModal}
                    action={(winner) => {
                        closeAppeal(appealToClose._id, winner)
                    }}
                    action2={() => {
                        setActiveActualAmountModal("progress")
                        setTimeout(() => {
                            setActiveActualAmountModal("active")
                        }, 100)
                    }}
                    message={`В чью пользу закрываем апелляцию #${appealToClose?.number}?`}
                    purpose={"winner"}
                    setWinner={setWinner}
                    setModalMessage={setModalMessage}
                    buttonText1={"Клиент"}
                    buttonText2={"Трейдер"}
                    appealToClose={appealToClose}
                />
                <ModalImgViewer
                    active={activeImgModal}
                    setActive={setActiveImgModal}
                    imgSrc={imgSrc?.replace(/\\/g, "/")}
                    setImgSrc={setImgSrc}
                />

                <ModalDialog
                    active={activeActualAmountModal}
                    setActive={setActiveActualAmountModal}
                    action={() => {

                        closeAppeal(appealToClose._id, winner)
                        // setActiveActionModal("progress")
                        // setTimeout(() => {
                        //     setActiveActionModal("active")
                        // }, 100)
                    }}
                    message={modalMessage}
                    purpose={"choice"}
                />


            </div>
            :
            processing
                ?
                <img src={require('../../img/loading.gif')} height={"40px"}
                     className={"margin-left-20"}/>
                :
                <h2>Нет ничего соответствующего вашему запросу</h2>






    );
};

export default SupportAppealTable;